import { IParallax } from "@react-spring/parallax";

const scrollAction = ({
  page,
  goHome = false,
  parallax,
}: {
  page: number;
  goHome?: boolean;
  parallax?: React.RefObject<IParallax>;
}) => {
  if (parallax?.current) {
    parallax.current.scrollTo(page);
  } else {
    window.scrollTo(0, 0);
  }

  if (goHome) window.scrollTo(0, 0);
};

export default scrollAction;
