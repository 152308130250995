interface BlogHomeMockInterface {
  title: string;
  subtitle: string;
  quote: string;
  authorQuote: string;
  invest: string;
  market: string;
  titleFirstArticle: string;
  authorFirstArticle: string;
  titleSecondArticle: string;
  authorSecondArticle: string;
  titleThirdArticle: string;
  authorThirdArticle: string;
  descriptionThirdArticle: string;
}

const blogHomeMock: BlogHomeMockInterface = {
  title: "Blog",
  subtitle: "Artículos destacados",
  quote: "«Una inversión en conocimiento paga el mejor interés.»",
  authorQuote: "–Benjamin Franklin",
  invest: "Inversión",
  market: "Mercados",
  titleFirstArticle: "Las FIBRAS y cómo invertir en ellas",
  authorFirstArticle: "por Daniel Jiménez",
  titleSecondArticle: "¿Por qué invertir en arte ahora?",
  authorSecondArticle: "por Álvaro Enríquez",
  titleThirdArticle: "¿Cómo hacer mi portafolio de inversión?",
  authorThirdArticle: "por Arturo Juárez",
  descriptionThirdArticle:
    "Convertir tus ahorros en inversiones es muy sencillo. Pero debes de hacerlo de manera responsable y segura.",
};

export default blogHomeMock;
