import { useEffect, useState } from "react";
import "./BlogProfile.css";
import blogProfileMock from "../../../mocks/blogProfile.mock";
import firstImageMock from "../../../assets/mocks/blogImageMock3.png";
import secondImageMock from "../../../assets/mocks/blogImageMock4.png";
import thirdImageMock from "../../../assets/mocks/blogImageMock5.png";
import fourthImageMock from "../../../assets/mocks/blogImageMock6.png";
import firstMobileMock from "../../../assets/mocks/blogImageMock12.png";
import secondMobileMock from "../../../assets/mocks/blogImageMock13.png";
import thirdMobileMock from "../../../assets/mocks/blogImageMock14.png";
import fourthMobileMock from "../../../assets/mocks/blogImageMock15.png";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";
import getDevice from "../../../utils/getters/getDevice";

const BlogProfile = ({
  onChangeHandler,
}: {
  onChangeHandler: (cpnt: "working" | "blog") => void;
}) => {
  const {
    title,
    subtitle,
    link,
    invest,
    crypto,
    titleFirstArticle,
    titleSecondArticle,
    titleThirdArticle,
    titleFourthArticle,
    authorFirstArticle,
    authorSecondArticle,
    authorThirdArticle,
    authorFourthArticle,
  } = blogProfileMock;
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const device = getDevice();
    setIsMobile(device === "mobile");
  }, []);

  return (
    <div
      className="BlogProfile"
      onMouseEnter={() => onChangeHandler("blog")}
      onTouchStart={() => onChangeHandler("blog")}
    >
      <div className="BlogProfile-Title">
        <h3>{title}</h3>
        <h1>{subtitle}</h1>
        <a href="/blog">{link}</a>
      </div>
      <div className="BlogProfile-Content">
        <div className="BlogProfile-Article">
          <img
            src={isMobile ? firstMobileMock : firstImageMock}
            alt="First-Article"
          />
          <h4>{invest}</h4>
          <h2>{titleFirstArticle}</h2>
          <h4>{authorFirstArticle}</h4>
        </div>
        <div className="BlogProfile-Article">
          <img
            src={isMobile ? secondMobileMock : secondImageMock}
            alt="Second-Article"
          />
          <h4>{crypto}</h4>
          <h2>{titleSecondArticle}</h2>
          <h4>{authorSecondArticle}</h4>
        </div>
        <div className="BlogProfile-Article">
          <img
            src={isMobile ? thirdMobileMock : thirdImageMock}
            alt="Third-Article"
          />
          <h4>{crypto}</h4>
          <h2>{titleThirdArticle}</h2>
          <h4>{authorThirdArticle}</h4>
        </div>
        <div className="BlogProfile-Article">
          <img
            src={isMobile ? fourthMobileMock : fourthImageMock}
            alt="Fourth-Article"
          />
          <h4>{invest}</h4>
          <h2>{titleFourthArticle}</h2>
          <h4>{authorFourthArticle}</h4>
        </div>
      </div>
      <WhatsappCpnt button="Agenda tu cita" />
    </div>
  );
};

export default BlogProfile;
