import labels from "../../fixtures/privacy.fixture";
import "./PrivacyNotice.css";

const PrivacyNotice = ({ button }: { button: string }) => {
  return (
    <div className="privacy-notice">
      <h1>{labels.title}</h1>
      <h3>{labels.subtitle}</h3>
      <p>{labels.introduction}</p>
      <p>{labels.twoforall}</p>
      <p>{labels.firstInfo}</p>
      <p>{labels.dataPersonalInfo}</p>
      <p>{labels.dataObtainedInfo}</p>
      <p>{labels.dataNotSharedInfo}</p>
      <p>{labels.dataKnowInfo}</p>
      <p>{labels.dataFixInfo}</p>
      <p>{labels.dataDeleteInfo}</p>
      <p>{labels.dataOpposeInfo}</p>
      <p>{labels.lawProtectInfo}</p>
      <p>{labels.requestLaw}</p>
      <h4>{labels.email}</h4>
      <p>{labels.requestInfoNeeded}</p>
      <p>{labels.answerInfo}</p>
      <p>{labels.revokeInfo}</p>
      <p>{labels.revokeInfoFinished}</p>
      <p>{labels.revokeInfoKnow}</p>
      <h4>{labels.email}</h4>
      <p>{labels.requestRevokeNeeded}</p>
      <p>{labels.answer}</p>
      <p>{labels.alternativeContact}</p>
      <h4>{labels.phone}</h4>
      <p>{labels.finalInfo}</p>
      <p style={{ margin: 0 }}>{labels.questions}</p>
      <h4>{labels.email}</h4>
    </div>
  );
};

export default PrivacyNotice;
