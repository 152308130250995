import "./WhatsappCpnt.css";
import whatsapplogo from "../../../assets/images/whatsapp.png";
import openWhatsapp from "../../../utils/openWhatsapp";
import { useHistory } from "react-router-dom";

const WhatsappCpnt = ({
  button,
  height = "10%",
}: {
  button: string;
  height?: string;
}) => {
  const history = useHistory();

  return (
    <div className="WhatsappCpnt" style={{ height }}>
      <button onClick={() => history.push("/contact")}>{button}</button>
      <img src={whatsapplogo} alt="whatsapp" onClick={openWhatsapp} />
    </div>
  );
};

export default WhatsappCpnt;
