import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";

// Views
import Home from "./views/Home";
import About from "./views/About";
import Services from "./views/Services";
import Steps from "./views/Steps";
import NotFound from "./views/NotFound";
import Blog from "./views/Blog";
import Help from "./views/Help";
import Contact from "./views/Contact";
import Privacy from "./views/Privacy";
import Terms from "./views/Terms";
import Article from "./views/Article";

const AppTwoforall = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/nosotros" component={About} />
    <Route path="/servicios" component={Services} />
    <Route path="/profile" component={Steps} />
    <Route path="/blog" component={Blog} />
    <Route path="/article/:articleId" component={Article} />
    <Route path="/ayuda" component={Help} />
    <Route path="/contacto/:topic?" component={Contact} />
    <Route path="/aviso" component={Privacy} />
    <Route path="/terminos" component={Terms} />
    <Route path="/404" component={NotFound} />
    <Redirect to="/404" />
  </Switch>
);

const App = () => (
  <div>
    <Router>
      <Route component={AppTwoforall} />
    </Router>
  </div>
);

export default App;
