import { services } from "../../constants";

const getArticles = async () => {
  try {
    const response = await fetch(services.blog, { method: "GET" });
    const res = await response.json();

    if (response.ok) {
      return { articles: res?.data?.posts?.nodes, error: false };
    }

    return { articles: null, error: true };
  } catch (error) {
    console.error(error);
    return { articles: null, error: true };
  }
};

export default getArticles;
