import { Row, Col } from "antd";
import { useHistory } from "react-router-dom";

import estateImage from "../../../assets/images/estate.png";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";
import "./Estate.css";

const Estate = ({
  title,
  titleRight,
  subtitleRight,
  descriptionRight,
  buttonLabel,
  button,
  onChangeHandler,
}: {
  title: string;
  titleRight: string;
  subtitleRight: string;
  descriptionRight: string;
  buttonLabel: string;
  button: string;
  onChangeHandler: (cpnt: string) => void;
}) => {
  const history = useHistory();

  return (
    <div
      className="estate"
      onMouseEnter={() => onChangeHandler("estate")}
      onTouchStart={() => onChangeHandler("estate")}
    >
      <Row justify="center" className="estate-row">
        <Col xs={20} className="estate-col-title">
          <h3>{title}</h3>
        </Col>
        <Col
          xs={20}
          lg={{ span: 12, offset: 2, order: 1 }}
          className="estate-col-text"
        >
          <h2>{titleRight}</h2>
          <h1>{subtitleRight}</h1>
          <p>{descriptionRight}</p>
          <a href="/contacto/estate">{buttonLabel}</a>
        </Col>
        <Col xs={20} lg={{ span: 8, offset: 2 }} className="estate-col-image">
          <img src={estateImage} alt="estate" />
          <button onClick={() => history.push("/contacto/estate")}>
            {buttonLabel}
          </button>
        </Col>
      </Row>
      <WhatsappCpnt button={button} />
    </div>
  );
};

export default Estate;
