import { useEffect, useState } from "react";
import { Layout } from "antd";
import HeaderCpnt from "../components/Header/Header";
import FooterCpnt from "../components/Footer/Footer";
import PrivacyNotice from "../components/PrivacyNotice/PrivacyNotice";
import twoforallWhite from "../assets/logos/twoforallWhite.svg";
import closeWhite from "../assets/icons/cancelWhite.png";
import menuWhite from "../assets/icons/recWhite.png";

const { Header, Footer, Content } = Layout;

const Privacy = () => {
  const [height, setHeight] = useState<string>("10%");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          height,
          background: "#000",
          padding: 0,
          lineHeight: 1.5715,
        }}
      >
        <HeaderCpnt
          image={twoforallWhite}
          closeImage={closeWhite}
          menuImage={menuWhite}
          color="#fff"
          button="Conoce tu perfil"
          setHeight={setHeight}
        />
      </Header>
      <Content>
        <PrivacyNotice button="Agenda tu cita" />
      </Content>
      <Footer style={{ padding: 0 }}>
        <FooterCpnt />
      </Footer>
    </Layout>
  );
};

export default Privacy;
