import { Row, Col } from "antd";
import { InlineWidget } from "react-calendly";
import "./ContactCalendar.css";

const ContactCalendar = ({
  title,
  titleLeft,
  firstDescLeft,
  secondDescLeft,
  onChangeHandler,
}: {
  title: string;
  titleLeft: string;
  firstDescLeft: string;
  secondDescLeft: string;
  onChangeHandler: (cpnt: string) => void;
}) => {
  return (
    <Row
      justify="center"
      className="contact"
      onMouseEnter={() => onChangeHandler("calendar")}
      onTouchStart={() => onChangeHandler("calendar")}
    >
      <Col xs={20} className="contact-col-title">
        <h3>{title}</h3>
      </Col>
      <Col xs={20} lg={12} className="contact-col-text">
        <h1>{titleLeft}</h1>
        <p>{firstDescLeft}</p>
        <p>{secondDescLeft}</p>
      </Col>
      <Col xs={20} lg={8} className="contact-col-calendar">
        <InlineWidget
          styles={{ width: "100%", height: "100%" }}
          url="https://calendly.com/twoforallmx"
          pageSettings={{
            backgroundColor: "#cb333b",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            textColor: "#fff",
          }}
        />
      </Col>
    </Row>
  );
};

export default ContactCalendar;
