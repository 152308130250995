import { Row, Col } from "antd";

import "./AboutUs.css";
import grinder from "../../../assets/images/grinder.png";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";

const AboutUs = ({
  title,
  subtitle,
  description,
  button,
  onChangeHandler,
}: {
  title: string;
  subtitle: string;
  description: string;
  button: string;
  onChangeHandler: (cpnt: string) => void;
}) => {
  return (
    <div
      className="about"
      id="about-us"
      onMouseEnter={() => onChangeHandler("about")}
      onTouchStart={() => onChangeHandler("about")}
    >
      <Row justify="center" className="about-row">
        <Col
          xs={{ span: 20, offset: 1 }}
          lg={{ span: 14, offset: 2, order: 1 }}
          className="about-col-text"
        >
          <h2>{title}</h2>
          <h1>{subtitle}</h1>
          <p>{description}</p>
        </Col>
        <Col
          xs={{ span: 20, offset: 1 }}
          lg={{ span: 6, offset: 2 }}
          className="about-col-img"
        >
          <img className="about-img" src={grinder} alt="grinder" />
        </Col>
      </Row>
      <WhatsappCpnt button={button} />
    </div>
  );
};

export default AboutUs;
