import { Row, Col } from "antd";
import { useHistory } from "react-router-dom";

import cryptoImage from "../../../assets/images/crypto.png";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";
import "./Crypto.css";

const Crypto = ({
  title,
  titleRight,
  subtitleRight,
  descriptionRight,
  buttonLabel,
  button,
  onChangeHandler,
}: {
  title: string;
  titleRight: string;
  subtitleRight: string;
  descriptionRight: string;
  buttonLabel: string;
  button: string;
  onChangeHandler: (cpnt: string) => void;
}) => {
  const history = useHistory();

  return (
    <div
      className="crypto"
      onMouseEnter={() => onChangeHandler("crypto")}
      onTouchStart={() => onChangeHandler("crypto")}
    >
      <Row justify="center" className="crypto-row">
        <Col xs={20} className="crypto-col-title">
          <h3>{title}</h3>
        </Col>
        <Col
          xs={20}
          lg={{ span: 12, offset: 2, order: 1 }}
          className="crypto-col-text"
        >
          <h2>{titleRight}</h2>
          <h1>{subtitleRight}</h1>
          <p>{descriptionRight}</p>
          <a href="/contacto/crypto">{buttonLabel}</a>
        </Col>
        <Col xs={20} lg={{ span: 8, offset: 2 }} className="crypto-col-image">
          <img src={cryptoImage} alt="crypto" />
          <button onClick={() => history.push("/contacto/crypto")}>
            {buttonLabel}
          </button>
        </Col>
      </Row>
      <WhatsappCpnt button={button} />
    </div>
  );
};

export default Crypto;
