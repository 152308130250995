import { IParallax, Parallax, ParallaxLayer } from "@react-spring/parallax";
import Introduction from "../Home/Introduction/Introduction";
import FindYourProfile from "../Home/FindYourProfile/FindYourProfile";
import LabelsHomeInterface from "../../interfaces/home.interface";
import peak from "../../assets/images/peak.png";
import "../../App.css";

const Sticky = ({
  labels,
  onChangeHandler,
  parallax,
}: {
  labels: LabelsHomeInterface;
  onChangeHandler: (cpnt: string) => void;
  parallax: React.RefObject<IParallax>;
}) => {
  const { introduction, findYourProfile } = labels;

  return (
    <div className="sticky-cpnt">
      <Parallax ref={parallax} pages={2} style={{ background: "#000" }}>
        <ParallaxLayer
          offset={0}
          speed={0}
          className="peak"
          sticky={{ start: 0, end: 1 }}
        >
          <div className="peak-right">
            <img src={peak} alt="peak" />
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          offset={0}
          speed={0}
          style={{ background: "#000" }}
          onMouseEnter={() => onChangeHandler("introduction")}
          onTouchStart={() => onChangeHandler("introduction")}
        >
          <Introduction title={introduction.title} button="Agenda tu cita" />
        </ParallaxLayer>
        <ParallaxLayer
          offset={1}
          speed={0}
          style={{
            background: "#edb8bb",
          }}
          onMouseEnter={() => onChangeHandler("find")}
          onTouchStart={() => onChangeHandler("find")}
        >
          <FindYourProfile
            title={findYourProfile.title}
            description={findYourProfile.description}
            findProfile={findYourProfile.bottomLeft}
            buttonLabel={findYourProfile.button}
            quote={findYourProfile.bottomRight}
            link={findYourProfile.link}
            button="Agenda tu cita"
          />
        </ParallaxLayer>
      </Parallax>
    </div>
  );
};

export default Sticky;
