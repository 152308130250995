import "./CardArticle.css";

interface CardArticleProps {
  title: string;
  author: string;
  topic: string;
  image: string;
  onClickHanler: () => void;
}

const CardArticle = ({
  title,
  author,
  topic,
  image,
  onClickHanler,
}: CardArticleProps) => {
  return (
    <div className="CardArticle" onClick={onClickHanler}>
      <img src={image} alt="CardArticleImage" width="100%" />
      <h4>{topic}</h4>
      <h2>{title}</h2>
      <h4>{author}</h4>
    </div>
  );
};

export default CardArticle;
