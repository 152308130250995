import { ArrowLeftOutlined } from "@ant-design/icons";
import { useState } from "react";
import { message } from "antd";

import "./Step.css";
import SpinnerModal from "../UI/SpinnerModal/SpinnerModal";
import hand from "../../assets/images/hand.png";
import handUp from "../../assets/images/handUp.png";
import labels from "../../fixtures/steps.fixture";
import Steps from "../../enums/steps.enum";
import { services } from "../../constants";
import requestProfile from "../../utils/requestProfile";
import messages from "../../fixtures/messages.fixture";

const Step10 = ({
  beginner,
  intermediate,
  advanced,
  onPreviousHandler,
  setIsEnd,
}: {
  beginner: number;
  intermediate: number;
  advanced: number;
  onPreviousHandler: (previousStep: Steps) => void;
  setIsEnd: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [typedName, setTypedName] = useState<string | null>(null);
  const [typedSurname, setTypedSurname] = useState<string | null>(null);
  const [typedPhone, setTypedPhone] = useState<string | null>(null);
  const [typedEmail, setTypedEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { step10, placeholders } = labels;
  const { title, legend, dot, name, surname, phone, email, button } = step10;

  const onSendHandler = async () => {
    if (!typedName) return;
    if (!typedSurname) return;
    if (!typedPhone) return;
    if (!typedEmail) return;

    try {
      setLoading(true);
      const level =
        beginner > (intermediate || advanced)
          ? 1
          : intermediate > (beginner || advanced)
          ? 2
          : advanced > (beginner || intermediate)
          ? 3
          : 2;

      const response = await fetch(services.sendEmail, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: typedEmail, level }),
      });

      if (response.ok) {
        const { requested, error } = await requestProfile({
          name: typedName,
          lastname: typedSurname,
          email: typedEmail,
          phone: typedPhone,
        });

        if (requested) {
          console.info(messages.dataSuccess);
        }

        if (error) {
          console.error(messages.newsletterError);
        }

        message.success(messages.dataSuccess);
        setLoading(false);
        setIsEnd(true);
      } else {
        setLoading(false);
        message.error(
          "Revisa que hayas ingresado correctamente el correo electrónico"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="Step">
      <div className="StepFinal-Top">
        <div className="StepFinal-Left">
          <div className="StepFinal-Left-Title">
            <h1>{title}&nbsp;</h1>
            <h1>
              {typedName}
              {dot}
            </h1>
          </div>
          <div className="StepFinal-MobileHand">
            <p>{legend}</p>
            <img src={handUp} alt="HandUp" />
          </div>
          <div className="StepFinal-InputName">
            <p>{name}</p>
            <input
              placeholder={placeholders.name}
              onChange={(e) => setTypedName(e?.target?.value)}
            />
            <p>{surname}</p>
            <input
              placeholder={placeholders.surname}
              onChange={(e) => setTypedSurname(e?.target?.value)}
            />
          </div>
          <div className="StepFinal-InputMobile">
            <p>{name}</p>
            <input
              placeholder={placeholders.name}
              onChange={(e) => setTypedName(e?.target?.value)}
            />
          </div>
          <div className="StepFinal-InputMobile">
            <p>{surname}</p>
            <input
              placeholder={placeholders.surname}
              onChange={(e) => setTypedSurname(e?.target?.value)}
            />
          </div>
          <div className="StepFinal-Input">
            <p>{phone}</p>
            <input
              placeholder={placeholders.phone}
              onChange={(e) => setTypedPhone(e?.target?.value)}
            />
          </div>
          <div className="StepFinal-Input">
            <p>{email}</p>
            <input
              placeholder={placeholders.email}
              onChange={(e) => setTypedEmail(e?.target?.value)}
            />
          </div>
          <button onClick={onSendHandler}>{button}</button>
        </div>
        <div className="StepFinal-Right">
          <div className="StepFinal-Hand">
            <img src={hand} alt="Hand" />
          </div>
          <p>{legend}</p>
        </div>
      </div>
      <div className="StepFinal-Bottom">
        <ArrowLeftOutlined
          className="Step-Arrows"
          onClick={() => onPreviousHandler(Steps.nine)}
        />
        <div className="Progress">
          <div className="Progress-Bar" style={{ width: "97%" }} />
        </div>
      </div>
      <SpinnerModal show={loading} />
    </div>
  );
};

export default Step10;
