import LabelsNotFoundInterface from "../interfaces/notFound.interface";

const labels: LabelsNotFoundInterface = {
  title: "404",
  subtitle: "Estamos yendo en círculos",
  description:
    "La página que estabas buscando ya no existe, fue borrada o se traspapeló. Pero tal vez podemos ayudarte a encontrarla. Usa esta barra de búsqueda y averiguémoslo.",
  noWayOut: `“Seamos honestos: de esta no hay manera alguna de salir.”`,
  placeholder: "Escribe aquí alguna(s) palabra(s) clave...",
  button: "Buscar",
};

export default labels;
