import { Row, Col } from "antd";

import "./WorkingOn.css";
import hiFive from "../../../assets/images/hiFive.png";
import { LabelsStepsInterface } from "../../../interfaces/steps.interface";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";

const WorkingOn = ({
  labels,
  onChangeHandler,
}: {
  labels: LabelsStepsInterface;
  onChangeHandler: (cpnt: "working" | "blog") => void;
}) => (
  <div
    className="working"
    onMouseEnter={() => onChangeHandler("working")}
    onTouchStart={() => onChangeHandler("working")}
  >
    <Row justify="center" className="working-row">
      <Col xs={20} className="working-col-text">
        <h1>{labels.workingOn.title}</h1>
        <img src={hiFive} alt="Hi-Five" />
        <h2>{labels.workingOn.description}</h2>
        <div className="progress-end">
          <div className="progress-end-bar" />
        </div>
      </Col>
      <Col xs={20} className="working-col-arrow">
        <p>&#8595;</p>
      </Col>
    </Row>
    <WhatsappCpnt button="Agenda tu cita" />
  </div>
);

export default WorkingOn;
