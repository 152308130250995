const getDevice = () => {
  let hasTouchScreen = false;
  const mediaQuerie = matchMedia("(min-width: 1200px)");
  if ("maxTouchPoints" in navigator) {
    //hasTouchScreen = navigator.maxTouchPoints > 0;
    /* old conditions 
    if (navigator.maxTouchPoints > 0 && navigator.maxTouchPoints < 256) {
      hasTouchScreen = true
    } else {
      hasTouchScreen = false
    } */
    if (
      navigator.maxTouchPoints === 0 ||
      navigator.maxTouchPoints >= 256 ||
      mediaQuerie.matches
    ) {
      hasTouchScreen = false;
    } else {
      hasTouchScreen = true;
    }
  } else {
    const mQ =
      window.matchMedia("(pointer:coarse)") && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod|Opera Mini)\b/i.test(UA);
    }
  }

  return hasTouchScreen ? "mobile" : "desktop";
};

export default getDevice;
