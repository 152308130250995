import Step1 from "../../components/Steps/Step1";
import Step10 from "../../components/Steps/Step10";
import Step2 from "../../components/Steps/Step2";
import Step3 from "../../components/Steps/Step3";
import Step4 from "../../components/Steps/Step4";
import Step5 from "../../components/Steps/Step5";
import Step6 from "../../components/Steps/Step6";
import Step7 from "../../components/Steps/Step7";
import Step8 from "../../components/Steps/Step8";
import Step9 from "../../components/Steps/Step9";
import Steps from "../../enums/steps.enum";

const getSteps = ({
  step,
  beginner,
  intermediate,
  advanced,
  income,
  outgoings,
  invest,
  onNextHandler,
  onPreviousHandler,
  setIsEnd,
  setBeginner,
  setIntermediate,
  setAdvanced,
  setIncome,
  setOutgoings,
  setInvest,
}: {
  step: Steps;
  beginner: number;
  intermediate: number;
  advanced: number;
  income: "a" | "b" | "c" | "d" | "e";
  outgoings: "a" | "b" | "c" | "d" | "e";
  invest: "a" | "b" | "c" | "d" | "e";
  onNextHandler: (nextStep: Steps) => void;
  onPreviousHandler: (previousStep: Steps) => void;
  setIsEnd: React.Dispatch<React.SetStateAction<boolean>>;
  setBeginner: React.Dispatch<React.SetStateAction<number>>;
  setIntermediate: React.Dispatch<React.SetStateAction<number>>;
  setAdvanced: React.Dispatch<React.SetStateAction<number>>;
  setIncome: React.Dispatch<React.SetStateAction<"a" | "b" | "c" | "d" | "e">>;
  setOutgoings: React.Dispatch<
    React.SetStateAction<"a" | "b" | "c" | "d" | "e">
  >;
  setInvest: React.Dispatch<React.SetStateAction<"a" | "b" | "c" | "d" | "e">>;
}) => {
  switch (step) {
    case Steps.one:
      return <Step1 onNextHandler={onNextHandler} />;
    case Steps.two:
      return (
        <Step2
          onPreviousHandler={onPreviousHandler}
          onNextHandler={onNextHandler}
        />
      );
    case Steps.three:
      return (
        <Step3
          onPreviousHandler={onPreviousHandler}
          onNextHandler={onNextHandler}
        />
      );
    case Steps.four:
      return (
        <Step4
          onPreviousHandler={onPreviousHandler}
          onNextHandler={onNextHandler}
          setBeginner={setBeginner}
          setIntermediate={setIntermediate}
          setAdvanced={setAdvanced}
          setIncome={setIncome}
        />
      );
    case Steps.five:
      return (
        <Step5
          income={income}
          onPreviousHandler={onPreviousHandler}
          onNextHandler={onNextHandler}
          setBeginner={setBeginner}
          setIntermediate={setIntermediate}
          setAdvanced={setAdvanced}
          setOutgoings={setOutgoings}
        />
      );
    case Steps.six:
      return (
        <Step6
          outgoings={outgoings}
          onPreviousHandler={onPreviousHandler}
          onNextHandler={onNextHandler}
          setBeginner={setBeginner}
          setIntermediate={setIntermediate}
          setAdvanced={setAdvanced}
        />
      );
    case Steps.seven:
      return (
        <Step7
          onPreviousHandler={onPreviousHandler}
          onNextHandler={onNextHandler}
          setBeginner={setBeginner}
          setIntermediate={setIntermediate}
          setAdvanced={setAdvanced}
          setInvest={setInvest}
        />
      );
    case Steps.eight:
      return (
        <Step8
          invest={invest}
          onPreviousHandler={onPreviousHandler}
          onNextHandler={onNextHandler}
          setBeginner={setBeginner}
          setIntermediate={setIntermediate}
          setAdvanced={setAdvanced}
        />
      );
    case Steps.nine:
      return (
        <Step9
          onPreviousHandler={onPreviousHandler}
          onNextHandler={onNextHandler}
        />
      );
    case Steps.final:
      return (
        <Step10
          beginner={beginner}
          intermediate={intermediate}
          advanced={advanced}
          onPreviousHandler={onPreviousHandler}
          setIsEnd={setIsEnd}
        />
      );
    default:
      <span />;
  }
};

export default getSteps;
