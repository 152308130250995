import {
  LabelsFooterInterface,
  PlaceholdersFooterInterface,
} from "../interfaces/footer.interface";

const date = new Date();
const year = date.getFullYear();

const labels: LabelsFooterInterface = {
  title: "Twoforall.",
  subscribe: "Suscríbete al Newsletter",
  subscribeDescription:
    "Mantente al día sobre las últimas oportunidades y noticias de Twoforall.",
  help: "¿Necesitas ayuda?",
  helpDescription: (
    <>
      <p>
        Llena nuestro formato de <a href="/contact">contacto</a>, agenda una
        cita o contáctanos por WhatsApp.
      </p>
    </>
  ),
  contact: "Contacto",
  contactDescription:
    "Prolongación Paseo de la Reforma 625, int 103, Santa Fe, Paseo de las Lomas, Cuajimalpa de Morelos, 01330",
  city: "Ciudad de México",
  contactEmail: "contacto@twoforall.mx",
  instagram: "Instagram",
  map: "Facebook",
  questions: "Preguntas frecuentes",
  privacy: "Aviso de privacidad",
  legal: "Términos & Condiciones",
  button: "Suscribirme",
  onlyUp: `“Lo único que nos queda es ir hacia arriba.”`,
  copyright: `${year} - Sitio por `,
  studioAnotherKind: "A Studio of Another Kind.",
};

const placeholders: PlaceholdersFooterInterface = {
  name: "Nombre(s)",
  surname: "Apellido(s)",
  email: "Email",
};

export { labels, placeholders };
