interface BlogArticleMockInterface {
  title: string;
  articleTitle: string;
  articleAuthor: string;
  articleSubtitle: string;
  leftTitle: string;
  invest: string;
  market: string;
  crypto: string;
  authorAlvaro: string;
  authorDaniel: string;
  authorArturo: string;
  fiberTitle: string;
  investArtTitle: string;
  mythsTitle: string;
  investPorfolioTitle: string;
  investPorfolioContent: string;
  createPorfolio: string;
  investPorfolioOne: string;
  investPorfolioTwo: string;
  investPorfolioThree: string;
  profileInvestTitle: string;
  profileInvestContent: string;
  profileInvestEnd: string;
}

const blogArticleMock: BlogArticleMockInterface = {
  title: "Portafolios de Inversión",
  articleTitle: "¿Cómo hacer mi portafolio de inversión?",
  articleAuthor: "por Arturo Juárez · Abril 29, 2021",
  articleSubtitle:
    "Convertir tus ahorros en inversiones es muy sencillo, pero debes hacerlo de manera responsable y segura.",
  leftTitle: "Historias recientes",
  invest: "Inversión",
  market: "Mercados",
  crypto: "Cripto Currency",
  authorAlvaro: "por Álvaro Enríquez",
  authorDaniel: "por Daniel Jiménez",
  authorArturo: "por Arturo Juárez",
  fiberTitle: "Las FIBRAS y cómo invertir en ellas",
  investArtTitle: "¿Por qué invertir en arte ahora?",
  mythsTitle: "Mitos sobre la inversión en Criptomoneda",
  investPorfolioTitle: "¿Qué es un portafolio de inversión?",
  investPorfolioContent:
    "Convertir tus ahorros en inversiones es muy sencillo. Pero para obtener los mejores resultados debes de hacerlo de manera responsable y segura. Para esto, lo mejor es crear un portafolio de inversión, este es la herramienta que utilizarás para poder llevar el control de tus activos. Tu portafolio está compuesto de una mezcla de instrumentos, como lo pueden ser: acciones, bonos, ETFs, FIBRAS, entre otros. Tu portafolio de inversión marca la pauta sobre como debes de invertir, pues según el portafolio que hayas elegido deberás de seguir invirtiendo de acuerdo a las composición de clases de activo que hayas elegido. Imagina tu portafolio de inversión como tu guía para saber en que debes de invertir.",
  createPorfolio:
    "La creación de un portafolio de inversión puede parecer confusa y complicada, pero te explicaremos como hacerlo en 3 sencillos pasos.",
  investPorfolioOne: "1. Definir tu perfil como inversionista",
  investPorfolioTwo: "2. Crear un portafolio base.",
  investPorfolioThree: "3. Mantenimiento del portafolio.",
  profileInvestTitle: "Conociendo mi perfil como inversionista",
  profileInvestContent:
    "El primer paso que te recomendamos seguir es definir tu perfil como inversionista, para poder elaborar tu portafolio debes de conocer cual es tu perfil, pues de este dependerá el riesgo que estas dispuesto a tomar y entonces podrás elegir la estrategia que mejor se acople a tus necesidades. Para realizar esta tarea te recomendamos leer nuestra publicación conociendo mi perfil de inversionista, con la q podrás aclarar tus dudas.",
  profileInvestEnd:
    "Una vez definido nuestro perfil de inversionista pasaremos a elaborar nuestro portafolio.",
};

export default blogArticleMock;
