import LabelsHeaderInterface from "../interfaces/header.interface";

const labels: LabelsHeaderInterface = {
  about: "Nosotros",
  services: "Servicios",
  blog: "Blog",
  contact: "Contacto",
  help: "Ayuda",
};

export default labels;
