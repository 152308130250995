import LabelsHelpInterface from "../interfaces/help.interface";

const answersFixture = {
  wealth: "1) Wealth Management",
  trading: "2) Trading",
  cripto: "3) Criptomoneda",
  art: "4) Inversión en arte",
  ine: "1)INE",
  act: "2)Acta de Hacienda",
  addressFiscal: "3)Domicilio fiscal de la persona física.",
};

const labels: LabelsHelpInterface = {
  title: "Preguntas Frecuentes",
  questions: [
    { question: "¿Puedo retirar mi dinero en cualquier momento?", index: 0 },
    {
      question:
        "¿Existe penalización por retirar el dinero antes de lo pactado?",
      index: 1,
    },
    {
      question:
        "¿Cómo funciona el impuesto en inversiones al ser persona física mexicana?",
      index: 2,
    },
    { question: "¿En qué se invierte mi dinero?", index: 3 },
    { question: "¿Por qué confiar en Twoforall?", index: 4 },
    { question: "¿Cuáles son los horarios de operación de Trading?", index: 5 },
    { question: "¿Qué documentos necesita Twoforall para invertir?", index: 6 },
    { question: "¿Cómo recibiré mis inversiones?", index: 7 },
    { question: "¿Como se hace el depósito de la inversión?", index: 8 },
    { question: "Horarios para hacer depósitos", index: 9 },
  ],
  answers: [
    {
      answer: (
        <>
          <p>
            Las utilidades se retiran cada 3 meses, hasta llegar a la fecha
            pactada.
          </p>
          <p>
            Las utilidades se entregan a la misma persona que hizo la inversión.
          </p>
        </>
      ),
    },
    {
      answer: (
        <p>
          Si, si no se cumple con el plazo establecido se hará una penalización
          del 5%.
        </p>
      ),
    },
    {
      answer: (
        <p>
          Para personas físicas se retiene el 20% y a su ves la persona debe de
          hacer su declaración anual ante el SAT.
        </p>
      ),
    },
    {
      answer: (
        <>
          <p>
            El dinero es invertido por expertos y nuestro servicio se divide en
            4 áreas:
          </p>
          <p>{answersFixture.wealth}</p>
          <p>{answersFixture.trading}</p>
          <p>{answersFixture.cripto}</p>
          <p>{answersFixture.art}</p>
        </>
      ),
    },
    {
      answer: (
        <p>
          Somos una empresa que se preocupa por sus clientes, buscando siempre
          darles un mejor rendimiento que cualquier otro banco. A su vez
          buscamos la tranquilidad de nuestros inversionistas, dándoles una
          garantía, que solo podrán hacer uso de ella si no se cumple con lo
          pactado. Por ello siempre tendrán la certeza de que su dinero estará
          seguro y garantizado.
        </p>
      ),
    },
    {
      answer: (
        <p>
          Nuestros horarios de servicio son de 9:00am a 6:pm de Lunes a Viernes.
        </p>
      ),
    },
    {
      answer: (
        <>
          <p>
            Toda esta información es de la persona que hará y recibirá las
            utilidades:
          </p>
          <p>{answersFixture.ine}</p>
          <p>{answersFixture.act}</p>
          <p>{answersFixture.addressFiscal}</p>
        </>
      ),
    },
    {
      answer: (
        <p>
          Las inversiones se te entregaran en una cuenta bancaría, a nombre de
          la persona que hizo la inversión.
        </p>
      ),
    },
    {
      answer: (
        <>
          <p>Se hace en una cuenta bancaria.</p>
          <p>
            Cuando se haga el contrato en en ese momento se realizara el
            deposito.
          </p>
        </>
      ),
    },
    { answer: <p>Los horarios para hacerlo es en horario bancario.</p> },
  ],
};

export default labels;
