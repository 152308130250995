import { useState } from "react";
import { Layout } from "antd";
import HeaderCpnt from "../components/Header/Header";
import FooterCpnt from "../components/Footer/Footer";
import Art from "../components/Services/Art/Art";
import Crypto from "../components/Services/Crypto/Crypto";
import Estate from "../components/Services/Estate/Estate";
import Trading from "../components/Services/Trading/Trading";
import Wealth from "../components/Services/Wealth/Wealth";
import twoforallWhite from "../assets/logos/twoforallWhite.svg";
import twoforallBlack from "../assets/logos/twoforallBlack.svg";
import closeWhite from "../assets/icons/cancelWhite.png";
import closeBlack from "../assets/icons/cancel.png";
import menuWhite from "../assets/icons/recWhite.png";
import menuBlack from "../assets/icons/rec.png";
import labels from "../fixtures/services.fixture";

const { Header, Footer, Content } = Layout;

const Services = () => {
  const { wealth, trading, estate, art, crypto } = labels;
  const [color, setColor] = useState<string>("#fff");
  const [image, setImage] = useState<string>(twoforallWhite);
  const [background, setBackground] = useState<string>("#cb333b");
  const [height, setHeight] = useState<string>("10%");
  const [closeImage, setCloseImage] = useState<string>(closeWhite);
  const [menuImage, setMenuImage] = useState<string>(menuWhite);

  const onChangeHandler = (cpnt: string) => {
    switch (cpnt) {
      case "wealth":
        setColor("#fff");
        setImage(twoforallWhite);
        setBackground("#cb333b");
        setCloseImage(closeWhite);
        setMenuImage(menuWhite);
        break;
      case "trading":
        setColor("#000");
        setImage(twoforallBlack);
        setBackground("#fff");
        setCloseImage(closeBlack);
        setMenuImage(menuBlack);
        break;
      case "estate":
        setColor("#fff");
        setImage(twoforallWhite);
        setBackground("#0857c3");
        setCloseImage(closeWhite);
        setMenuImage(menuWhite);
        break;
      case "art":
        setColor("#000");
        setImage(twoforallBlack);
        setBackground("#f6eb61");
        setCloseImage(closeBlack);
        setMenuImage(menuBlack);
        break;
      case "crypto":
        setColor("#fff");
        setImage(twoforallWhite);
        setBackground("#00c65e");
        setCloseImage(closeWhite);
        setMenuImage(menuWhite);
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          height,
          background,
          padding: 0,
          lineHeight: 1.5715,
        }}
      >
        <HeaderCpnt
          image={image}
          closeImage={closeImage}
          menuImage={menuImage}
          color={color}
          button="Conoce tu perfil"
          setHeight={setHeight}
        />
      </Header>
      <Content>
        <Wealth
          title={wealth.title}
          subtitle={wealth.subtitle}
          description={wealth.description}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
        <Trading
          title={trading.title}
          titleRight={trading.titleRight}
          subtitleRight={trading.subtitleRight}
          descriptionRight={trading.descriptionRight}
          buttonLabel={trading.buttonLabel}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
        <Estate
          title={estate.title}
          titleRight={estate.titleRight}
          subtitleRight={estate.subtitleRight}
          descriptionRight={estate.descriptionRight}
          buttonLabel={estate.buttonLabel}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
        <Art
          title={art.title}
          titleRight={art.titleRight}
          subtitleRight={art.subtitleRight}
          descriptionRight={art.descriptionRight}
          buttonLabel={art.buttonLabel}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
        <Crypto
          title={crypto.title}
          titleRight={crypto.titleRight}
          subtitleRight={crypto.subtitleRight}
          descriptionRight={crypto.descriptionRight}
          buttonLabel={crypto.buttonLabel}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
      </Content>
      <Footer style={{ padding: 0 }}>
        <FooterCpnt />
      </Footer>
    </Layout>
  );
};

export default Services;
