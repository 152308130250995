import { useState } from "react";
import { message } from "antd";

import "./BlogIntroduction.css";
import messages from "../../../fixtures/messages.fixture";
import handInvert from "../../../assets/images/handInvert.png";
import blogMock from "../../../mocks/blog.mock";
import portfolio from "../../../assets/mocks/blogImageMock2.png";
import buildings from "../../../assets/mocks/blogImageMock7.png";
import waveWhite from "../../../assets/images/waveWhite.svg";
import buildingsMobile from "../../../assets/mocks/blogImageMock.png";
import subscribeNewsletter from "../../../utils/subscribeNewsletter";
import getDevice from "../../../utils/getters/getDevice";

const BlogIntroduction = () => {
  const device = getDevice();
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const onNewsletterHandler = async () => {
    if (!name) {
      message.error(messages.requiredName);
      return;
    }

    if (!lastname) {
      message.error(messages.requiredLastName);
      return;
    }

    if (!email) {
      message.error(messages.requiredEmail);
      return;
    }

    const { subscribed, error } = await subscribeNewsletter({
      name,
      lastname,
      email,
    });

    if (subscribed) {
      message.success(messages.newsletterSuccess);
      return;
    }
    if (error) {
      message.error(messages.newsletterError);
      return;
    }
  };

  return (
    <div className="BlogIntroduction">
      <div className="BlogIntroduction-Left">
        <div className="BlogIntroduction-Left-Title">
          <h1>{blogMock.title}</h1>
          <img src={handInvert} alt="Hand" />
        </div>
        <p style={{ margin: "1rem 0 2rem" }}>{blogMock.description}</p>
        <h4 style={{ margin: "0 0 2rem" }}>{blogMock.subtitle}</h4>
        <img
          className="BlogIntroduction-Article-Image"
          src={portfolio}
          alt="PortfolioImage"
          style={{ margin: "0 0 2rem" }}
        />
        <h4>{blogMock.invest}</h4>
        <h2>{blogMock.titlePorfolioArticle}</h2>
        <p style={{ width: "55%" }}>{blogMock.descriptionPorfolioArticle}</p>
        <h4 style={{ margin: "0 0 1rem" }}>{blogMock.authorPorfolioArticle}</h4>
      </div>
      <div className="BlogIntroduction-Right">
        <h1 className="Title-Mobile">{blogMock.title}</h1>
        <h1>{blogMock.quote}</h1>
        <h4 style={{ margin: device === "mobile" ? "0 0 5%" : "0 0 10%" }}>
          {blogMock.authorQuote}
        </h4>
        <h4 className="Subtitle-Mobile">{blogMock.subtitle}</h4>
        <img className="Blog-Spiral" src={waveWhite} alt="LineSpiral" />
        <div className="BlogIntroduction-Right-Newsletter">
          <div className="BlogNewsletter-Description">
            <h2 style={{ width: "100%" }}>{blogMock.newsletterTitle}</h2>
            <p>{blogMock.newsletterDescription}</p>
          </div>
          <div className="BlogNewsletter-Form">
            <div className="BlogNewsletter-InputName">
              <input
                placeholder={blogMock.placeholders.name}
                onChange={(event) => setName(event.target.value)}
              />
              <input
                placeholder={blogMock.placeholders.surname}
                onChange={(event) => setLastname(event.target.value)}
              />
            </div>
            <div className="BlogNewsletter-Input">
              <input
                placeholder={blogMock.placeholders.email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="BlogNewsletter-Button">
              <button onClick={onNewsletterHandler}>
                {blogMock.newsletterButton}
              </button>
            </div>
          </div>
        </div>
        <img
          className="Blog-Spiral"
          src={waveWhite}
          alt="LineSpiral"
          style={{ margin: "0 0 8%" }}
        />
        <div className="BlogIntroduction-ArticleMobile">
          <img
            src={portfolio}
            alt="PortfolioImage"
            style={{ margin: "0 0 1rem" }}
          />
          <h4>{blogMock.invest}</h4>
          <h2>{blogMock.titlePorfolioArticle}</h2>
          <p>{blogMock.descriptionPorfolioArticle}</p>
          <h4 style={{ margin: "0 0 3rem" }}>
            {blogMock.authorPorfolioArticle}
          </h4>
        </div>
        <img
          src={device === "mobile" ? buildingsMobile : buildings}
          alt="buidings"
        />
        <h4>{blogMock.invest}</h4>
        <h2>{blogMock.titleFiberArticle}</h2>
        <h4>{blogMock.authorFiberArticle}</h4>
        <img
          className="SpiralMobile"
          src={waveWhite}
          alt="LineSpiral"
          style={{ width: "100%" }}
        />
        <div className="BlogIntroduction-NewsletterMobile">
          <div className="BlogNewsletterMobile-Description">
            <h2>{blogMock.newsletterTitle}</h2>
            <p>{blogMock.newsletterDescription}</p>
          </div>
          <div className="BlogNewsletterMobile-Form">
            <div className="BlogNewsletterMobile-InputName">
              <input
                placeholder={blogMock.placeholders.name}
                onChange={(event) => setName(event.target.value)}
              />
              <input
                placeholder={blogMock.placeholders.surname}
                onChange={(event) => setLastname(event.target.value)}
              />
            </div>
            <div className="BlogNewsletterMobile-Input">
              <input
                placeholder={blogMock.placeholders.email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="BlogNewsletterMobile-Button">
              <button onClick={onNewsletterHandler}>
                {blogMock.newsletterButton}
              </button>
            </div>
          </div>
        </div>
        <img
          className="SpiralMobile"
          src={waveWhite}
          alt="LineSpiral"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default BlogIntroduction;
