/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Layout } from "antd";
import HeaderCpnt from "../components/Header/Header";
import FooterCpnt from "../components/Footer/Footer";
import Twoforall from "../components/About/Twoforall/Twoforall";
import OurMethod from "../components/About/OurMethod/OurMethod";
import AboutUs from "../components/About/AboutUs/AboutUs";
import Invesment from "../components/About/Invesment/Invesment";
import twoforallWhite from "../assets/logos/twoforallWhite.svg";
import twoforallBlack from "../assets/logos/twoforallBlack.svg";
import closeWhite from "../assets/icons/cancelWhite.png";
import closeBlack from "../assets/icons/cancel.png";
import menuWhite from "../assets/icons/recWhite.png";
import menuBlack from "../assets/icons/rec.png";
import labels from "../fixtures/about.fixture";
import getDevice from "../utils/getters/getDevice";
import "../App.css";

const { Header, Footer, Content } = Layout;

const About = () => {
  const { twoforall, aboutUs, ourMethod, investment } = labels;
  const isMobile = getDevice() === "mobile";
  const initT = isMobile ? 80 : 50;
  const limitDowns = isMobile ? -60 : -12;
  const limitUps = isMobile ? 200 : 80;
  const [color, setColor] = useState<string>("#000");
  const [image, setImage] = useState<string>(twoforallBlack);
  const [background, setBackground] = useState<string>("#fff");
  const [height, setHeight] = useState<string>("10%");
  const [closeImage, setCloseImage] = useState<string>(closeBlack);
  const [menuImage, setMenuImage] = useState<string>(menuBlack);
  const [transform, setTransform] = useState<string>(
    `translate3d(0, ${initT}%, 0)`
  );
  let increaseY = initT;
  let lastScrollTop = 0;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const offsetHeightAbout =
        document.getElementById("about-us")?.offsetHeight;
      const offsetHeightInvest =
        document.getElementById("invest")?.offsetHeight;
      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (offsetHeightAbout && offsetHeightInvest) {
        const heightAbout = offsetHeightAbout * 0.7;

        if (st > lastScrollTop) {
          // downscroll code
          if (st < heightAbout || increaseY < limitDowns) return;
          increaseY -= 2;
          setTransform(`translate3d(0, ${increaseY}%, 0)`);
        } else {
          // upscroll code
          if (increaseY > limitUps) return;
          increaseY += 2;
          setTransform(`translate3d(0, ${increaseY}%, 0)`);
        }
      }

      lastScrollTop = st <= 0 ? 0 : st;
    });
  }, []);

  const onChangeHandler = (cpnt: string) => {
    switch (cpnt) {
      case "about":
        setColor("#000");
        setImage(twoforallBlack);
        setBackground("#fff");
        setCloseImage(closeBlack);
        setMenuImage(menuBlack);
        break;
      case "investment":
        setColor("#000");
        setImage(twoforallBlack);
        setBackground("#edb8bb");
        setCloseImage(closeBlack);
        setMenuImage(menuBlack);
        break;
      case "method":
        setColor("#000");
        setImage(twoforallBlack);
        setBackground("#fff");
        setCloseImage(closeBlack);
        setMenuImage(menuBlack);
        break;
      case "twoforall":
        setColor("#fff");
        setImage(twoforallWhite);
        setBackground("#cb333b");
        setCloseImage(closeWhite);
        setMenuImage(menuWhite);
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          height,
          background,
          padding: 0,
          lineHeight: 1.5715,
        }}
      >
        <HeaderCpnt
          image={image}
          closeImage={closeImage}
          menuImage={menuImage}
          color={color}
          button="Conoce tu perfil"
          setHeight={setHeight}
        />
      </Header>
      <Content>
        <AboutUs
          title={aboutUs.title}
          subtitle={aboutUs.subtitle}
          description={aboutUs.description}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
        <Invesment
          title={investment.title}
          description={investment.description}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
          transform={transform}
        />
        <OurMethod
          title={ourMethod.title}
          stepOne={{
            title: ourMethod.titleStepOne,
            description: ourMethod.descriptionStepOne,
          }}
          stepTwo={{
            title: ourMethod.titleStepTwo,
            description: ourMethod.descriptionStepTwo,
          }}
          stepThree={{
            title: ourMethod.titleStepThree,
            description: ourMethod.descriptionStepThree,
          }}
          stepFour={{
            title: ourMethod.titleStepFour,
            description: ourMethod.descriptionStepFour,
          }}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
        <Twoforall
          title={twoforall.title}
          topContent={{
            title: twoforall.title2,
            description: twoforall.description2,
          }}
          bottomContent={{
            title: twoforall.title3,
            description: twoforall.description3,
          }}
          link={twoforall.link}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
      </Content>
      <Footer style={{ padding: 0 }}>
        <FooterCpnt />
      </Footer>
    </Layout>
  );
};

export default About;
