import { useState } from "react";
import { Row, Col } from "antd";

import "./HelpCpnt.css";
import labels from "../../fixtures/help.fixture";

const HelpCpnt = ({ button }: { button: string }) => {
  const [idx, setIdx] = useState<number>(0);
  const [typed, setTyped] = useState<string | null>(null);

  const onClickHandler = (index: number) => {
    setIdx(index);
  };

  let questions = labels.questions.map((element) => {
    const textDecoration = element.index === idx ? "underline" : "none";

    return (
      <h4
        key={element.index}
        onClick={() => onClickHandler(element.index)}
        style={{ textDecoration }}
      >
        {element.question}
      </h4>
    );
  });

  if (typed) {
    const searchQuestions = labels.questions.filter((element) =>
      element.question.toLowerCase().includes(typed.toLowerCase())
    );

    questions = searchQuestions.map((element) => {
      const textDecoration = element.index === idx ? "underline" : "none";

      return (
        <h4
          key={element.index}
          onClick={() => onClickHandler(element.index)}
          style={{ textDecoration }}
        >
          {element.question}
        </h4>
      );
    });
  }

  const onSearchHandler = (value: string) => {
    const typedWord = value.match(/^[A-Za-z]+/g) ? value : null;

    setTyped(typedWord);
  };

  return (
    <Row justify="center" className="help">
      <Col xs={20} lg={8} className="help-col-q">
        <h1>{labels.title}</h1>
        <input
          placeholder="Buscar ..."
          onChange={(event) => onSearchHandler(event.target.value)}
        />
        {questions}
      </Col>
      <Col xs={20} lg={12} className="help-col-a">
        <h2>{labels.questions[idx].question}</h2>
        {labels.answers[idx].answer}
      </Col>
    </Row>
  );
};

export default HelpCpnt;
