import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import "./Header.css";
import hand from "../../assets/images/handInvert.png";
import whatsapplogo from "../../assets/images/whatsapp.png";
import openWhatsapp from "../../utils/openWhatsapp";
import labels from "../../fixtures/header.fixture";

const Header = ({
  image,
  closeImage,
  menuImage,
  color,
  button,
  setHeight,
}: {
  image: string;
  closeImage: string;
  menuImage: string;
  color: string;
  button: string;
  setHeight: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const location = useLocation();
  const history = useHistory();
  const isBlack = color === "#000";
  const [selectedKey, setSelectedKey] = useState<string>("");
  const [isClassActive, setIsClassActive] = useState<boolean>(false);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  return (
    <div className="header-tfa" style={{ color }}>
      <div className="navbar" style={{ color }}>
        <a href="/" className="navbar-logo">
          <img src={image} alt="Twoforall" />
        </a>
        <div
          className={
            isClassActive
              ? "navbar-mobile-button active"
              : "navbar-mobile-button"
          }
        >
          <button onClick={() => history.push("/profile")}>{button}</button>
        </div>
        <ul
          className={
            isClassActive
              ? "navbar-menu active"
              : isBlack
              ? "navbar-menu black"
              : "navbar-menu"
          }
        >
          <li style={{ color }}>
            <a
              href="/nosotros"
              className={
                selectedKey === "/nosotros"
                  ? isBlack
                    ? "navbar-list black"
                    : "navbar-list"
                  : ""
              }
            >
              {labels.about}
            </a>
          </li>
          <li>
            <a
              href="/servicios"
              className={
                selectedKey === "/servicios"
                  ? isBlack
                    ? "navbar-list black"
                    : "navbar-list"
                  : ""
              }
            >
              {labels.services}
            </a>
          </li>
          <li>
            <a
              href="/blog"
              className={
                selectedKey === "/blog"
                  ? isBlack
                    ? "navbar-list black"
                    : "navbar-list"
                  : ""
              }
            >
              {labels.blog}
            </a>
          </li>
          <li>
            <a
              href="/contacto"
              className={
                selectedKey === "/contacto"
                  ? isBlack
                    ? "navbar-list black"
                    : "navbar-list"
                  : ""
              }
            >
              {labels.contact}
            </a>
          </li>
          <li>
            <a
              href="/ayuda"
              className={
                selectedKey === "/ayuda"
                  ? isBlack
                    ? "navbar-list black"
                    : "navbar-list"
                  : ""
              }
            >
              {labels.help}
            </a>
          </li>
        </ul>
        <button
          className="navbar-button"
          onClick={() => {
            history.push("/contacto");
          }}
        >
          Agenda tu cita
        </button>
        <div
          className={isClassActive ? "navbar-blank active" : "navbar-blank"}
        />
        <div
          className={isClassActive ? "navbar-footer active" : "navbar-footer"}
        >
          <img
            className="navbar-close"
            src={closeImage}
            alt="Close"
            onClick={() => {
              setIsClassActive((prevState) => !prevState);
              if (!isClassActive) {
                setHeight("100%");
              } else {
                setHeight("10%");
              }
            }}
          />
          <img
            className="navbar-whats"
            src={whatsapplogo}
            alt="whatsapp"
            onClick={openWhatsapp}
          />
        </div>
      </div>
      <div className="navbar-mobile">
        <img className="mobile-image" src={hand} alt="Hand" />
        <button
          onClick={() => {
            setIsClassActive((prevState) => !prevState);
            if (!isClassActive) {
              setHeight("100%");
            } else {
              setHeight("10%");
            }
          }}
        >
          <img className="button-image" src={menuImage} alt="Rec" />
        </button>
      </div>
    </div>
  );
};

export default Header;
