import LabelsHomeInterface from "../interfaces/home.interface";

const labels: LabelsHomeInterface = {
  introduction: {
    title: "Investment is for People",
  },
  findYourProfile: {
    title: "And People are all different",
    description: (
      <>
        <p>
          Somos una agencia revolucionaria de{" "}
          <b>
            <i>Wealth Management</i>
          </b>{" "}
          y el mejor equipo de expertos del cual podrías formar parte. Conoce tu
          perfil de inversionista y da el primer paso. ¡Pongamos tu dinero a
          trabajar!
        </p>
      </>
    ),
    button: "Comencemos >",
    bottomLeft: "Averigua tu perfil de inversionista",
    bottomRight: `"El primer paso hacia la cima."`,
    link: "Averigua tu perfil de inversionista >",
  },
  ourServices: {
    title: "Nuestros Servicios",
    button: "Aprender más >",
    titleRight: "Twoforall es",
    subtitleRight: "Wealth Management",
    descriptionRight:
      "Tracemos una estrategia única y personalizada para maximizar tus activos, reducir el riesgo y alcanzar tus objetivos. Pero eso no es todo...",
  },
};

export default labels;
