interface ServicesInterface {
  sendEmail: string;
  blog: string;
  newsletter: string;
  contact: string;
  profile: string;
}

// url for develop mode
// const API = "http://localhost:5001/twoforall-94c07/us-central1";
const API = "https://us-central1-twoforall-94c07.cloudfunctions.net";

const services: ServicesInterface = {
  sendEmail: `${API}/sendEmail`,
  blog: `${API}/getArticles`,
  newsletter: `${API}/subscribeNewsletter`,
  contact: `${API}/requestInfo`,
  profile: `${API}/getProfile`,
};

export { services };
