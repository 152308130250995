import {
  PropsDecreaseIncomeInterface,
  PropsDecreaseInvestInterface,
  PropsDecreaseOutgoingsInterface,
  PropsIncreaseIncomeInterface,
  PropsIncreaseInvestInterface,
  PropsIncreaseOutgoingsInterface,
} from "../interfaces/steps.interface";

const increaseIncome = (propsIncreaseIncome: PropsIncreaseIncomeInterface) => {
  const {
    selectedValue,
    setBeginner,
    setIntermediate,
    setAdvanced,
    setIncome,
  } = propsIncreaseIncome;

  switch (selectedValue) {
    case "a":
    case "b":
      setBeginner((count) => count + 1);
      setIncome(selectedValue);
      break;
    case "c":
      setIntermediate((count) => count + 1);
      setIncome(selectedValue);
      break;
    case "d":
    case "e":
      setAdvanced((count) => count + 1);
      setIncome(selectedValue);
      break;
    default:
      break;
  }
};

const decreaseIncome = (propsDecreaseIncome: PropsDecreaseIncomeInterface) => {
  const { income, setBeginner, setIntermediate, setAdvanced } =
    propsDecreaseIncome;

  switch (income) {
    case "a":
    case "b":
      setBeginner((count) => count - 1);
      break;
    case "c":
      setIntermediate((count) => count - 1);
      break;
    case "d":
    case "e":
      setAdvanced((count) => count - 1);
      break;
    default:
      break;
  }
};

const increaseOutgoings = (
  propsIncreaseOutgoings: PropsIncreaseOutgoingsInterface
) => {
  const {
    selectedValue,
    setBeginner,
    setIntermediate,
    setAdvanced,
    setOutgoings,
  } = propsIncreaseOutgoings;

  switch (selectedValue) {
    case "a":
      setAdvanced((count) => count + 1);
      setOutgoings(selectedValue);
      break;
    case "b":
    case "c":
      setIntermediate((count) => count + 1);
      setOutgoings(selectedValue);
      break;
    case "d":
    case "e":
      setBeginner((count) => count + 1);
      setOutgoings(selectedValue);
      break;
    default:
      break;
  }
};

const decreaseOutgoings = (
  propsDecreaseOutgoings: PropsDecreaseOutgoingsInterface
) => {
  const { outgoings, setBeginner, setIntermediate, setAdvanced } =
    propsDecreaseOutgoings;

  switch (outgoings) {
    case "a":
      setAdvanced((count) => count - 1);
      break;
    case "b":
    case "c":
      setIntermediate((count) => count - 1);
      break;
    case "d":
    case "e":
      setBeginner((count) => count - 1);
      break;
    default:
      break;
  }
};

const increaseInvest = (propsIncreaseInvest: PropsIncreaseInvestInterface) => {
  const {
    selectedValue,
    setBeginner,
    setIntermediate,
    setAdvanced,
    setInvest,
  } = propsIncreaseInvest;

  switch (selectedValue) {
    case "a":
    case "b":
      setBeginner((count) => count + 1);
      setInvest(selectedValue);
      break;
    case "c":
    case "d":
      setIntermediate((count) => count + 1);
      setInvest(selectedValue);
      break;
    case "e":
      setAdvanced((count) => count + 1);
      setInvest(selectedValue);
      break;
    default:
      break;
  }
};

const decreaseInvest = (propsDecreaseInvest: PropsDecreaseInvestInterface) => {
  const { invest, setBeginner, setIntermediate, setAdvanced } =
    propsDecreaseInvest;

  switch (invest) {
    case "a":
    case "b":
      setBeginner((count) => count - 1);
      break;
    case "c":
    case "d":
      setIntermediate((count) => count - 1);
      break;
    case "e":
      setAdvanced((count) => count - 1);
      break;
    default:
      break;
  }
};
export {
  increaseIncome,
  decreaseIncome,
  increaseOutgoings,
  decreaseOutgoings,
  increaseInvest,
  decreaseInvest,
};
