import { Radio } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useState } from "react";

import "./Step.css";
import labels from "../../fixtures/steps.fixture";
import Steps from "../../enums/steps.enum";

const Step3 = ({
  onPreviousHandler,
  onNextHandler,
}: {
  onPreviousHandler: (previousStep: Steps) => void;
  onNextHandler: (nextStep: Steps) => void;
}) => {
  const { title, description, option1, option2, option3, option4, option5 } =
    labels.step3;
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const onRightHandler = () => {
    if (selectedValue) {
      onNextHandler(Steps.four);
    }
  };

  return (
    <div className="Step">
      <div className="Step-Top">
        <div className="Step-Left">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="Step-Right">
          <Radio.Group
            className="Step-Radio"
            onChange={(e) => setSelectedValue(e?.target?.value)}
          >
            <Radio.Button value="a" className="Step-RadioButton">
              {option1}
            </Radio.Button>
            <Radio.Button value="b" className="Step-RadioButton">
              {option2}
            </Radio.Button>
            <Radio.Button value="c" className="Step-RadioButton">
              {option3}
            </Radio.Button>
            <Radio.Button value="d" className="Step-RadioButton">
              {option4}
            </Radio.Button>
            <Radio.Button value="e" className="Step-RadioButton">
              {option5}
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className="Step-Bottom">
        <ArrowLeftOutlined
          className="Step-Arrows"
          onClick={() => onPreviousHandler(Steps.two)}
        />
        <div className="Progress">
          <div className="Progress-Bar" style={{ width: "25%" }} />
        </div>
        <ArrowRightOutlined className="Step-Arrows" onClick={onRightHandler} />
      </div>
    </div>
  );
};

export default Step3;
