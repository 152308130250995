interface BlogProfileMockInterface {
  title: string;
  subtitle: string;
  link: string;
  invest: string;
  crypto: string;
  titleFirstArticle: string;
  authorFirstArticle: string;
  titleSecondArticle: string;
  authorSecondArticle: string;
  titleThirdArticle: string;
  authorThirdArticle: string;
  titleFourthArticle: string;
  authorFourthArticle: string;
}

const blogProfileMock: BlogProfileMockInterface = {
  title: "Algunas historias de nuestro blog",
  subtitle: "¿No sabes por dónde comenzar?",
  link: "Explora el blog y nuestros recursos educativos >",
  invest: "Inversión",
  crypto: "Cripto Currency",
  titleFirstArticle: "¿Cómo hacer mi portafolio de inversión?",
  authorFirstArticle: "por Arturo Juárez",
  titleSecondArticle: "¿Cómo comenzar a invertir?",
  authorSecondArticle: "por Álvaro Enríquez",
  titleThirdArticle: "Mitos sobre la inversión en Criptomoneda",
  authorThirdArticle: "por Arturo Juárez",
  titleFourthArticle: "Las FIBRAS y cómo invertir en ellas",
  authorFourthArticle: "por Daniel Jiménez",
};

export default blogProfileMock;
