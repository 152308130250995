import { LabelsStepsInterface } from "../interfaces/steps.interface";

const labels: LabelsStepsInterface = {
  step1: {
    title: "1. Comencemos por tu edad",
    description:
      "Queremos conocerte, determinar tu perfil de inversión y trabajar contigo. ¿Cuántos años tienes?",
    option1: "18 a 25 años",
    option2: "26 a 35 años",
    option3: "36 a 45 años",
    option4: "46 a 55 años",
    option5: "56 años o más",
  },
  step2: {
    title: "2. Ahora, tu género",
    description: "¿Cuál es tu género?",
    option1: "Masculino",
    option2: "Femenino",
    option3: "Otro",
  },
  step3: {
    title: "3. Dependientes económicos",
    description: "¿Cuántos dependientes económicos tienes?",
    option1: "Ninguno",
    option2: "1",
    option3: "2",
    option4: "3",
    option5: "4 o más",
  },
  step4: {
    title: "4. Tus ingresos mensuales",
    description:
      "¿Cuáles son tus ingresos netos mensuales? Una cantidad aproximada basta (en MXN).",
    option1: "Menos de $20,000",
    option2: "$21,000 a $49,000",
    option3: "$50,000 a $79,000",
    option4: "$80,000 a $120,000",
    option5: "Más de $120,000",
  },
  step5: {
    title: "5. Y si conoces tus gastos",
    description:
      "Aproximadamente, ¿qué porcentaje de tus ingresos se va en gastos?",
    option1: "Menos del 60%",
    option2: "Entre 60-70%",
    option3: "Entre 70-80%",
    option4: "Entre 80-90%",
    option5: "Entre 90-100%",
  },
  step6: {
    title: "6. Ahora, más específico",
    description: "¿Cuentas ya con algún tipo de cuenta de inversión?",
    option1: "Sí",
    option2: "No",
  },
  step7: {
    title: "7. ¿Posible inversión?",
    description: "¿Cuánto tienes disponibe para invertir?",
    option1: "Menos de $500,000",
    option2: "Entre $500,000 y 1 millón",
    option3: "Entre 1 millón y 1 millón y medio",
    option4: "Entre 1 millón y medio y 3 millones",
    option5: "Más de $3,000,000",
  },
  step8: {
    title: "8. Y de esa posible inversión",
    description:
      "¿Necesitarás el total o la mayor parte para pagar algún compromiso en particular?",
    option1: "Por ahora, no",
    option2: "Sí, en menos de un año",
    option3: "Sí, en menos de 3 años",
    option4: "Sí, en menos de 5 años",
    option5: "Sí, pero no en su totalidad",
  },
  step9: {
    title: "9. Finalmente, una prueba",
    description:
      "¿Qué harías si entras a tu portafolio de inversión y notas que tienes una pérdida del 10%",
    option1: "Llamaría muy molesto a mi asesor",
    option2: "Esperaría para ver qué sucede",
    option3: "Le pediría a mi asesor una mayor inversión",
    option4: "Le preguntaría a mi asesor el motivo",
    option5: "Nada. La fluctuación del mercado es normal",
  },
  step10: {
    title: "Muchas gracias, ",
    legend: `“No soportaríamos no llamarte por tu nombre.”`,
    dot: ".",
    name: "Nombre(s)",
    surname: "Apellido(s)",
    phone: "Teléfono",
    email: "Email",
    button: "Enviar",
  },
  placeholders: {
    name: "e.g. José Luis",
    surname: "e.g. Cabrera",
    phone: "+52 1 5555 555 555",
    email: "jl.cabrera@gmail.com",
  },
  workingOn: {
    title: "¡Manos a la obra!",
    description:
      "Nos pondremos en contacto para darte a conocer tus resultados.",
  },
};

export default labels;
