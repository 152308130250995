import LabelsServicesInterface from "../interfaces/services.interface";

const labels: LabelsServicesInterface = {
  wealth: {
    title: "Esto es",
    subtitle: "Wealth Management",
    description:
      "Información, experiencia y garantías tangibles para minimizar los riesgos, maximizar la liquidez y lograr grandes retornos. Hay un mundo de posibilidades e información y queremos explorarlo contigo. Diversifica y diversifica un poco más: haz un portafolio a la medida de tus objetivos.",
  },
  trading: {
    title: "Algunas de nuestras opciones",
    titleRight: "01",
    subtitleRight: "Trading & Bolsa",
    descriptionRight:
      "Fondos de inversión, ETFs, acciones y compraventa de activos con gran liquidez en el mercado nacional e internacional: una buena alternativa a largo plazo.",
    buttonLabel: "Esto me interesa >",
  },
  estate: {
    title: "Algunas de nuestras opciones",
    titleRight: "02",
    subtitleRight: "Inversión Inmobiliaria",
    descriptionRight:
      "Inversión especializada en bienes raíces, con el respaldo de expertos inmobiliarios y asesoría de primer nivel para crear un patrimonio.",
    buttonLabel: "Esto me interesa >",
  },
  art: {
    title: "Algunas de nuestras opciones",
    titleRight: "03",
    subtitleRight: "Inversión en Arte",
    descriptionRight:
      "Un mercado creciente, diverso y emocionante: invertir en arte es un arte y una gran oportunidad para diversificar tu portafolio.",
    buttonLabel: "Esto me interesa >",
  },
  crypto: {
    title: "Algunas de nuestras opciones",
    titleRight: "04",
    subtitleRight: "Crypto Currency",
    descriptionRight:
      "Activos descentralizados, atractivos y muy seguros para la inversión. Explora distintas monedas y carteras de forma completamente digital.",
    buttonLabel: "Esto me interesa >",
  },
};

export default labels;
