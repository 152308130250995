import { LabelsContactInterface } from "../interfaces/contact.interface";

const labels: LabelsContactInterface = {
  form: {
    title: "Ponte en contacto",
    subtitle: "Nuestras oficinas",
    address:
      "Prolongación Paseo de la Reforma 625, int 103, Santa Fe, Paseo de las Lomas, Cuajimalpa de Morelos, 01330",
    mexicoCity: "Ciudad de México",
    emailContact: "contacto@twoforall.mx",
    iWouldLikeToInvest: "Me gustaría invertir en",
    button: "Enviar",
    description:
      "Nuestro equipo puede ponerse en contacto y asesorarte donde quiera que estés; sin embargo, puedes también agendar una cita y visitar nuestras oficinas en la Ciudad de México.",
    options: {
      trading: "Trading",
      art: "Arte",
      estate: "Inmuebles",
      crypto: "Criptomoneda",
      iDontKnow: "Aún no estoy seguro",
      terms: "Acepto los términos & condiciones",
      privacy: "Acepto el aviso de privacidad",
    },
    placeholders: {
      name: "Nombre(s)",
      surname: "Apellido(s)",
      email: "Email",
      phone: "Teléfono",
    },
  },
  calendar: {
    title: "Asesoría personalizada",
    oneToOne: "Tête à tête! (uno a uno)",
    firstStep:
      "Una sesión uno a uno: el primer paso para presentarnos, discutir tus necesidades, expectativas y objetivos. ¡Queremos conocerte!",
    secondStep:
      "Elige una fecha y hora y uno de nuestros asesores se pondrá en contacto para indicarte los pasos a seguir.",
    chooseDate: "Selecciona una fecha y hora",
  },
};

export default labels;
