import LabelsPrivacyInterface from "../interfaces/privacy.interface";

const labels: LabelsPrivacyInterface = {
  title: "Aviso de Privacidad",
  subtitle: "Última actualización · Octubre 7, 2021",
  introduction:
    "De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, Twoforall, S.A. de C.V. pone a su disposición el siguiente aviso de privacidad.",
  twoforall:
    "Twoforall, S.A. de C.V. es responsable del uso y protección de sus datos personales; por tanto, con el fin de dar cabal cumplimiento a las obligaciones legales establecidas en la norma antes señalada, por virtud de este aviso, se informa a los titulares de los datos, la información que esta empresa recaba de ellos y el uso que se le dará a la misma.",
  firstInfo:
    "En primer lugar, le informamos que Twoforall, S.A. de C.V. tiene su domicilio en Prolongación Paseo de la Reforma 625, despacho 103, Santa Fe, Paseo de las Lomas, Álvaro Obregón, en la Ciudad de México.",
  dataPersonalInfo:
    "Asimismo, le informamos que los datos personales que obtenemos de usted serán utilizados para las finalidades estrictamente necesarias y vinculadas con la relación que tenemos con usted. Es decir, para (i) prestarle información del producto y/o servicio que está interesado en adquirir; (ii) enviarle información de marketing relacionada con el producto y/o servicio que prestamos; y (iii) enviarle correos electrónicos con información de promociones relacionadas con el producto y/o servicio que usted esté interesado en adquirir.",
  dataObtainedInfo:
    "Para efectos de lo antes señalado, obtenemos y utilizamos los siguientes datos personales que usted nos proporcione a través de nuestra página de internet (i) nombre; (ii) edad; (iii) en su caso, número de dependientes económicos; (iv) género; (v) ingresos mensuales; (vi) gastos mensuales; y (vii) correo electrónico.",
  dataNotSharedInfo:
    "Es importante que usted tenga la certeza de que sus datos personales no serán compartidos con ningún tercero, ya sea autoridad, empresa o persona jurídica, organización o persona distintas a nosotros y serán utilizados exclusivamente para los fines señalados.",
  dataKnowInfo:
    "Le informamos que tiene en todo momento el derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos; que conforme a la norma citada se conoce como derecho de “acceso”.",
  dataFixInfo:
    "Asimismo, tiene derecho a solicitar la corrección de su información personal, en el supuesto de que esté desactualizada, sea inexacta o esté incompleta; es decir, a la “rectificación” de sus datos personales.",
  dataDeleteInfo:
    "Además, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente, es decir, que se “cancelen” sus datos personales.",
  dataOpposeInfo:
    "Finalmente, tiene derecho a “oponerse” al uso de sus datos personales para fines específicos.",
  lawProtectInfo:
    "A estos derechos, conforme a la Ley Federal de Protección de Datos Personales en Posesión de Particulares, se les denormina derechos ARCO.",
  requestLaw:
    "La forma en que usted puede ejercer estos derechos, es a través de la presentación de una solicitud, por escrito, al correo electrónico:",
  email: "twoforall.contacto@gmail.com",
  requestInfoNeeded:
    "La solicitud antes señalada deberá contener la siguiente información: (i) nombre del solicitante; (ii) teléfono; y (iii) correo electrónico.",
  answerInfo:
    "Un vez recibida la solicitud, usted recibirá respuesta en un plazo máximo de 2 (dos) semanas, a través del correo electrónico que usted haya señalado en dicha solicitud.",
  revokeInfo:
    "Debe usted saber que puede revocar, en cualquier momento, su consentimiento para el uso o tratamiento de sus datos personales. No obstante, es importante que tenga conocimiento de que no en todos los casos podremos atender de forma inmediata su solicitud; esto, cuando alguna obligación de índole legal que implique que tengamos que seguir con el tratamiento de sus datos previamente consentido por usted.",
  revokeInfoFinished:
    "De igual manera, le informamos que, en algunos casos, la revocación del uso y/o tratamiento de sus datos personales podría implicar que la relación que tengamos con usted se dé por terminada y nos veamos impedidos para seguir prestándole el servicio contratado.",
  revokeInfoKnow:
    "Para revocar su consentimiento, en términos de lo señalado en los párrafos precedentes, deberá presentar su solicitud por escrito al correo electrónico:",
  requestRevokeNeeded:
    "Su solicitud deberá contener los siguientes datos: (i) nombre del solicitante; (ii) teléfono; y (iii) correo electrónico.",
  answer:
    "Nosotros daremos respuesta por escrito al correo electrónico que señale en su solicitud, en un plazo máximo de 2 (dos) semanas.",
  alternativeContact:
    "Con el fin de atender de la manera más adecuada cualquier solicitud relacionada con el uso y tratamiento de sus datos personales, así como cualquier duda que tenga, también puede comunicarse con nosotros al teléfono:",
  phone: "5576695403",
  finalInfo:
    "Finalmente, es importante que conozca que el presente aviso de privacidad puede ser modificado, cambiado o actualizado, si así lo requiren las disposiciones legales aplicables; o bien, porque existan cambios en los productos y servicios que ofrecemos, en nuestro modelo de negocio o por cualquier otra causa. No obstante, en este momento nos obligamos a mantenerlo informado de cualquier cambio que llegare a ocurrir.",
  questions:
    "Para cualquier información relacionada con el presente aviso de privacidad, por favor comuníquese al correo electrónico:",
};

export default labels;
