import "./SpinnerModal.css";

const SpinnerModal = ({ show }: { show: boolean }) => {
  const display = show ? "block" : "none";

  return (
    <div className="SpinnerModal" style={{ display }}>
      <div className="LoaderModal">Loading...</div>
    </div>
  );
};

export default SpinnerModal;
