import { Row, Col } from "antd";

import "./Twoforall.css";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";

interface ContentLabels {
  title: string;
  description: string;
}

const Twoforall = ({
  title,
  topContent,
  bottomContent,
  link,
  button,
  onChangeHandler,
}: {
  title: string;
  topContent: ContentLabels;
  bottomContent: ContentLabels;
  link: string;
  button: string;
  onChangeHandler: (cpnt: string) => void;
}) => {
  return (
    <div
      className="twoforall"
      onMouseEnter={() => onChangeHandler("twoforall")}
      onTouchStart={() => onChangeHandler("twoforall")}
    >
      <Row justify="center" className="twoforall-row">
        <Col xs={20} lg={8} className="twoforall-col-title">
          <h1>{title}</h1>
        </Col>
        <Col xs={20} lg={12} className="twoforall-col-text">
          <h1>{topContent.title}</h1>
          <p>{topContent.description}</p>
          <h1>{bottomContent.title}</h1>
          <p>{bottomContent.description}</p>
          <a href="/servicios">{link}</a>
        </Col>
      </Row>
      <WhatsappCpnt button={button} />
    </div>
  );
};

export default Twoforall;
