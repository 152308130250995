import { Pagination } from "antd";
import { useHistory } from "react-router-dom";
import "./BlogPagination.css";
import blogMock from "../../../mocks/blog.mock";
import CardArticle from "./CardArticle/CardArticle";
import getDevice from "../../../utils/getters/getDevice";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";

const BlogPagination = ({ pages }: { pages: number }) => {
  const history = useHistory();
  const isMobile = getDevice() === "mobile";
  const screenWidth = window.innerWidth;
  const articlesMock = blogMock.mockArticles.map((element, indx) => (
    <CardArticle
      key={indx}
      title={element.title}
      author={element.author}
      topic={element.topic}
      image={element.image}
      onClickHanler={() => {
        history.push(`/article/${indx}`);
      }}
    />
  ));

  const articlesMockMobile = blogMock.mockArticles
    .slice(0, 3)
    .map((element, indx) => (
      <CardArticle
        key={indx}
        title={element.title}
        author={element.author}
        topic={element.topic}
        image={element.image}
        onClickHanler={() => {
          history.push(`/article/${indx}`);
        }}
      />
    ));

  return (
    <div className="BlogPagination">
      <hr />
      <div className="BlogPagination-Header">
        <h2>{blogMock.themes}</h2>
        <div className="BlogPagination-Header-Select">
          <h4>{blogMock.all}</h4>
          <h4>{blogMock.invest}</h4>
          <h4>{blogMock.personalFinance}</h4>
          <h4>{blogMock.crypto}</h4>
          <h4>{blogMock.trading}</h4>
        </div>
        <input placeholder={blogMock.placeholders.search} />
      </div>
      <div className="BlogPagination-Content">
        {isMobile && screenWidth <= 800 ? articlesMockMobile : articlesMock}
        <Pagination
          defaultCurrent={1}
          total={50}
          pageSize={isMobile && screenWidth <= 800 ? 3 : 6}
        />
      </div>
      <WhatsappCpnt button="Agenda tu cita" />
    </div>
  );
};

export default BlogPagination;
