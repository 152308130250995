import "./NotFoundCpnt.css";
import labels from "../../fixtures/notFound.fixture";
import notFound from "../../assets/images/404.png";
import WhatsappCpnt from "../UI/WhatsappCpnt/WhatsappCpnt";

const NotFoundCpnt = ({ button }: { button: string }) => {
  return (
    <div className="NotFound">
      <div className="NotFound-Top">
        <div className="NotFound-Left">
          <h1>{labels.title}</h1>
          <h1>{labels.subtitle}</h1>
          <p>{labels.description}</p>
          <div className="NotFound-Search">
            <input placeholder={labels.placeholder} />
            <button>{labels.button}</button>
          </div>
        </div>
        <div className="NotFound-Right">
          <img src={notFound} alt="NotFound" />
          <p>{labels.noWayOut}</p>
          <span className="NotFound-Paragraph">{labels.description}</span>
          <div className="NotFound-SearchMobile">
            <input placeholder="Buscar ..." />
            <button>{labels.button}</button>
          </div>
        </div>
      </div>
      <WhatsappCpnt button={button} />
    </div>
  );
};

export default NotFoundCpnt;
