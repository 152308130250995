import { useState } from "react";
import { Layout } from "antd";
import HeaderCpnt from "../components/Header/Header";
import FooterCpnt from "../components/Footer/Footer";
import WorkingOn from "../components/Steps/WorkingOn/WorkingOn";
import BlogProfile from "../components/Steps/BlogProfile/BlogProfile";
import twoforallWhite from "../assets/logos/twoforallWhite.svg";
import twoforallBlack from "../assets/logos/twoforallBlack.svg";
import closeWhite from "../assets/icons/cancelWhite.png";
import closeBlack from "../assets/icons/cancel.png";
import menuWhite from "../assets/icons/recWhite.png";
import menuBlack from "../assets/icons/rec.png";
import labels from "../fixtures/steps.fixture";

const { Header, Footer, Content } = Layout;

const StepEnds = () => {
  const [color, setColor] = useState<string>("#fff");
  const [image, setImage] = useState<string>(twoforallWhite);
  const [background, setBackground] = useState<string>("#cb333b");
  const [height, setHeight] = useState<string>("10%");
  const [closeImage, setCloseImage] = useState<string>(closeWhite);
  const [menuImage, setMenuImage] = useState<string>(menuWhite);

  const onChangeHandler = (cpnt: "working" | "blog") => {
    switch (cpnt) {
      case "working":
        setColor("#fff");
        setImage(twoforallWhite);
        setBackground("#cb333b");
        setCloseImage(closeWhite);
        setMenuImage(menuWhite);
        break;
      case "blog":
        setColor("#000");
        setImage(twoforallBlack);
        setBackground("#fff");
        setCloseImage(closeBlack);
        setMenuImage(menuBlack);
        break;
    }
  };

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          height,
          background,
          padding: 0,
          lineHeight: 1.5715,
        }}
      >
        <HeaderCpnt
          image={image}
          closeImage={closeImage}
          menuImage={menuImage}
          color={color}
          button="Conoce tu perfil"
          setHeight={setHeight}
        />
      </Header>
      <Content>
        <WorkingOn labels={labels} onChangeHandler={onChangeHandler} />
        <BlogProfile onChangeHandler={onChangeHandler} />
      </Content>
      <Footer style={{ padding: 0 }}>
        <FooterCpnt />
      </Footer>
    </Layout>
  );
};

export default StepEnds;
