import { Checkbox, Row, message } from "antd";
import { useState } from "react";
import "./ContactForm.css";
import requestInfo from "../../../utils/requestInfo";
import messages from "../../../fixtures/messages.fixture";
import { PropsContactFormInterface } from "../../../interfaces/contact.interface";

const ContactForm = (propsContactForm: PropsContactFormInterface) => {
  const {
    title,
    subtitle,
    address,
    mexicoCity,
    emailContact,
    placeholders,
    button,
    description,
    options,
    iWouldLikeToInvest,
    topicSelected,
    onChangeHandler,
  } = propsContactForm;
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const onContactHandler = async () => {
    if (!name) {
      message.error(messages.requiredName);
      return;
    }

    if (!lastname) {
      message.error(messages.requiredLastName);
      return;
    }

    if (!email) {
      message.error(messages.requiredEmail);
      return;
    }

    if (!phone) {
      message.error(messages.requiredEmail);
    }

    const { contacted, error } = await requestInfo({
      name,
      lastname,
      email,
      phone,
    });

    if (contacted) {
      message.success(messages.dataSuccess);
      return;
    }
    if (error) {
      message.error(messages.newsletterError);
      return;
    }
  };

  return (
    <div
      className="ContactForm"
      onMouseEnter={() => onChangeHandler("form")}
      onTouchStart={() => onChangeHandler("form")}
    >
      <div className="ContactForm-Left">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        <p>{address}</p>
        <p>{mexicoCity}</p>
        <h3>{emailContact}</h3>
      </div>
      <div className="ContactForm-Right">
        <h1>{title}</h1>
        <div className="ContactForm-Form">
          <div className="ContactForm-InputName">
            <input
              placeholder={placeholders.name}
              onChange={(event) => setName(event.target.value)}
            />
            <input
              placeholder={placeholders.surname}
              onChange={(event) => setLastname(event.target.value)}
            />
          </div>
          <div className="ContactForm-Input">
            <input
              placeholder={placeholders.phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
          <div className="ContactForm-Input">
            <input
              placeholder={placeholders.email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="ContactForm-Mobile">
            <p>{placeholders.name}</p>
            <input
              placeholder={placeholders.name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div className="ContactForm-Mobile">
            <p>{placeholders.surname}</p>
            <input
              placeholder={placeholders.surname}
              onChange={(event) => setLastname(event.target.value)}
            />
          </div>
          <div className="ContactForm-Mobile">
            <p>{placeholders.phone}</p>
            <input
              placeholder={placeholders.phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
          <div className="ContactForm-Mobile">
            <p>{placeholders.email}</p>
            <input
              placeholder={placeholders.email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <h3>{iWouldLikeToInvest}</h3>
          <div className="ContactForm-Button">
            <Checkbox.Group
              style={{ width: "inherit" }}
              className="ContactForm-Checkbox"
              defaultValue={[topicSelected]}
            >
              <div className="Checkbox-Left">
                <Row>
                  <Checkbox style={{ color: "#fff" }} value="art">
                    {options.art}
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox style={{ color: "#fff" }} value="trading">
                    {options.trading}
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox style={{ color: "#fff" }} value="crypto">
                    {options.crypto}
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox style={{ color: "#fff" }} value="estate">
                    {options.estate}
                  </Checkbox>
                </Row>
              </div>
              <div className="Checkbox-Right">
                <Row>
                  <Checkbox style={{ color: "#fff" }} value="terms">
                    {options.terms}
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox style={{ color: "#fff" }} value="terms">
                    {options.privacy}
                  </Checkbox>
                </Row>
                <div className="Button-Checkbox">
                  <button onClick={onContactHandler}>{button}</button>
                </div>
              </div>
            </Checkbox.Group>
          </div>
        </div>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ContactForm;
