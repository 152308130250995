import "./BlogArticle.css";
import blogArticleMock from "../../../mocks/blogArticle.mock";
import facebook from "../../../assets/logos/facebook.png";
import twitter from "../../../assets/logos/twitter.png";
import whatsapp from "../../../assets/logos/whatsapp.png";
import linkedin from "../../../assets/logos/linkedin.png";
import portfolio from "../../../assets/mocks/blogImageMock11.png";
import waveBlack from "../../../assets/images/waveBlack.svg";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";
import getDevice from "../../../utils/getters/getDevice";

const BlogArticle = ({ button }: { button: string }) => {
  const isMobile = getDevice() === "mobile";
  const screenWidth = window.screen.width;

  return (
    <div className="BlogArticle">
      <div className="BlogArticle-Top">
        <h3>{blogArticleMock.title}</h3>
        <h1>{blogArticleMock.articleTitle}</h1>
        <h3>{blogArticleMock.articleAuthor}</h3>
        <img src={portfolio} alt="Article Title" />
        <h2>{blogArticleMock.articleSubtitle}</h2>
        <hr style={{ margin: "2rem 0 0" }} />
        <hr style={{ borderStyle: "dashed" }} />
      </div>
      <div className="BlogArticle-Middle">
        <div className="BlogArticle-Left">
          <div className="BlogArticle-Social">
            <img src={facebook} alt="facebook" />
            <img src={twitter} alt="twitter" />
            <img src={whatsapp} alt="whatsapp" />
            <img src={linkedin} alt="linkedin" />
          </div>
          <div className="BlogArticle-Sections">
            <h2>{blogArticleMock.leftTitle}</h2>
            <img src={waveBlack} alt="Wave" />
            <h4>{blogArticleMock.invest}</h4>
            <h3>{blogArticleMock.fiberTitle}</h3>
            <h4>{blogArticleMock.authorDaniel}</h4>
            <hr />
            <h4>{blogArticleMock.market}</h4>
            <h3>{blogArticleMock.investArtTitle}</h3>
            <h4>{blogArticleMock.authorAlvaro}</h4>
            <hr />
            <h4>{blogArticleMock.crypto}</h4>
            <h3>{blogArticleMock.mythsTitle}</h3>
            <h4>{blogArticleMock.authorAlvaro}</h4>
            <hr />
            <h4>{blogArticleMock.invest}</h4>
            <h3>{blogArticleMock.fiberTitle}</h3>
            <h4>{blogArticleMock.authorArturo}</h4>
          </div>
        </div>
        <div className="BlogArticle-Right">
          <div className="BlogArticle-SocialMobile">
            <img src={facebook} alt="facebook" />
            <img src={twitter} alt="twitter" />
            <img src={whatsapp} alt="whatsapp" />
            <img src={linkedin} alt="linkedin" />
          </div>
          <h3>{blogArticleMock.investPorfolioTitle}</h3>
          <p>{blogArticleMock.investPorfolioContent}</p>
          <p>{blogArticleMock.createPorfolio}</p>
          <p style={{ margin: "0" }}>{blogArticleMock.investPorfolioOne}</p>
          <p style={{ margin: "0" }}>{blogArticleMock.investPorfolioTwo}</p>
          <p style={{ margin: "0 0 1rem" }}>
            {blogArticleMock.investPorfolioThree}
          </p>
          <h3>{blogArticleMock.profileInvestTitle}</h3>
          <p>{blogArticleMock.profileInvestContent}</p>
          <p>{blogArticleMock.profileInvestEnd}</p>
        </div>
      </div>
      <WhatsappCpnt
        button={button}
        height={isMobile || screenWidth >= 1792 ? "5%" : "10%"}
      />
    </div>
  );
};

export default BlogArticle;
