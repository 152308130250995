import pyramid from "../assets/mocks/blogImageMock8.png";
import glassess from "../assets/mocks/blogImageMock9.png";
import select from "../assets/mocks/blogImageMock10.png";

interface ArticleMockInterface {
  title: string;
  author: string;
  image: string;
  topic: string;
}

interface BlogMockInterface {
  title: string;
  subtitle: string;
  description: string;
  quote: string;
  authorQuote: string;
  all: string;
  personalFinance: string;
  crypto: string;
  trading: string;
  invest: string;
  market: string;
  titleFiberArticle: string;
  authorFiberArticle: string;
  titlePorfolioArticle: string;
  authorPorfolioArticle: string;
  descriptionPorfolioArticle: string;
  newsletterTitle: string;
  newsletterDescription: string;
  newsletterButton: string;
  placeholders: {
    name: string;
    surname: string;
    email: string;
    search: string;
  };
  themes: string;
  mockArticles: Array<ArticleMockInterface>;
}

const articleMock: ArticleMockInterface = {
  title: "Las FIBRAS y cómo invertir en ellas",
  author: "por Daniel Jiménez",
  topic: "Inversión",
  image: "",
};

const blogMock: BlogMockInterface = {
  title: "Blog",
  subtitle: "Historias destacadas",
  description:
    "Innovación, reportes, tutoriales y lo último en Wealth Management. Explora todos nuestros recursos y comienza a invertir.",
  quote: "«Vacía tu bolsillo en tu mente y tu mente llenará tu bolsillo.»",
  authorQuote: "–Benjamin Franklin",
  all: "Todos",
  personalFinance: "Finanzas personales",
  crypto: "Criptomoneda",
  trading: "Trading",
  invest: "Inversión",
  market: "Mercados",
  titleFiberArticle: "Las FIBRAS y cómo invertir en ellas",
  authorFiberArticle: "por Daniel Jiménez",
  titlePorfolioArticle: "¿Cómo hacer mi portafolio de inversión?",
  authorPorfolioArticle: "por Arturo Juárez",
  descriptionPorfolioArticle:
    "Convertir tus ahorros en inversiones es muy sencillo. Pero debes de hacerlo de manera responsable y segura.",
  newsletterTitle: "Suscríbete al Newsletter",
  newsletterDescription:
    "Mantente al día sobre las últimas oportunidades y noticias de Twoforall.",
  newsletterButton: "Suscribirme",
  placeholders: {
    name: "Nombre(s)",
    surname: "Apellido(s)",
    email: "Email",
    search: "Buscar...",
  },
  themes: "Temas",
  mockArticles: [
    { ...articleMock, image: pyramid },
    { ...articleMock, image: glassess },
    { ...articleMock, image: select },
    { ...articleMock, image: pyramid },
    { ...articleMock, image: glassess },
    { ...articleMock, image: select },
  ],
};

export default blogMock;
