import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "antd";
import HeaderCpnt from "../components/Header/Header";
import FooterCpnt from "../components/Footer/Footer";
import ContactForm from "../components/Contact/ContactForm/ContactForm";
import ContactCalendar from "../components/Contact/ContactCalendar/ContactCalendar";
import twoforallWhite from "../assets/logos/twoforallWhite.svg";
import closeWhite from "../assets/icons/cancelWhite.png";
import menuWhite from "../assets/icons/recWhite.png";
import labels from "../fixtures/contact.fixture";

const { Header, Footer, Content } = Layout;

const Contact = () => {
  const { topic } = useParams<{ topic: string }>();
  const { form, calendar } = labels;
  const [color, setColor] = useState<string>("#fff");
  const [background, setBackground] = useState<string>("#000");
  const [height, setHeight] = useState<string>("10%");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeHandler = (cpnt: string) => {
    switch (cpnt) {
      case "form":
        setColor("#fff");
        setBackground("#000");
        break;
      case "calendar":
        setColor("#fff");
        setBackground("#cb333b");
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          height,
          background,
          padding: 0,
          lineHeight: 1.5715,
        }}
      >
        <HeaderCpnt
          image={twoforallWhite}
          closeImage={closeWhite}
          menuImage={menuWhite}
          color={color}
          button="Conoce tu perfil"
          setHeight={setHeight}
        />
      </Header>
      <Content>
        <ContactForm
          title={form.title}
          subtitle={form.subtitle}
          address={form.address}
          mexicoCity={form.mexicoCity}
          emailContact={form.emailContact}
          options={form.options}
          button={form.button}
          description={form.description}
          placeholders={form.placeholders}
          iWouldLikeToInvest={form.iWouldLikeToInvest}
          topicSelected={topic}
          onChangeHandler={onChangeHandler}
        />

        <ContactCalendar
          title={calendar.title}
          titleLeft={calendar.oneToOne}
          firstDescLeft={calendar.firstStep}
          secondDescLeft={calendar.secondStep}
          onChangeHandler={onChangeHandler}
        />
      </Content>
      <Footer style={{ padding: 0 }}>
        <FooterCpnt />
      </Footer>
    </Layout>
  );
};

export default Contact;
