import { services } from "../constants";

const requestProfile = async ({
  email,
  name,
  lastname,
  phone,
}: {
  email: string;
  name: string;
  lastname: string;
  phone: string;
}) => {
  try {
    const response = await fetch(services.profile, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, name, lastname, phone }),
    });

    if (response.ok) {
      return { requested: true, error: false };
    }

    return { requested: false, error: true };
  } catch (error) {
    console.error(error);
    return { requested: false, error: true };
  }
};

export default requestProfile;
