import { useState } from "react";

import "./Stepper.css";
import getSteps from "../../utils/getters/getSteps";
import Steps from "../../enums/steps.enum";
import WhatsappCpnt from "../UI/WhatsappCpnt/WhatsappCpnt";

const Stepper = ({
  setIsEnd,
}: {
  setIsEnd: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [step, setStep] = useState<Steps>(Steps.one);
  const [beginner, setBeginner] = useState<number>(0);
  const [intermediate, setIntermediate] = useState<number>(0);
  const [advanced, setAdvanced] = useState<number>(0);
  const [income, setIncome] = useState<"a" | "b" | "c" | "d" | "e">("a");
  const [outgoings, setOutgoings] = useState<"a" | "b" | "c" | "d" | "e">("a");
  const [invest, setInvest] = useState<"a" | "b" | "c" | "d" | "e">("a");

  const onNextHandler = (nextStep: Steps) => {
    setStep(nextStep);
  };

  const onPreviousHandler = (previousStep: Steps) => {
    setStep(previousStep);
  };

  return (
    <div className="Stepper">
      <div className="Stepper-Top">
        {getSteps({
          step,
          beginner,
          intermediate,
          advanced,
          income,
          outgoings,
          invest,
          onNextHandler,
          onPreviousHandler,
          setIsEnd,
          setBeginner,
          setIntermediate,
          setAdvanced,
          setIncome,
          setOutgoings,
          setInvest,
        })}
      </div>
      <WhatsappCpnt button="Agenda tu cita" />
    </div>
  );
};

export default Stepper;
