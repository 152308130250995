import MessagesInterface from "../interfaces/messages.interface";

const messages: MessagesInterface = {
  requiredName: "El nombre es requerido.",
  requiredLastName: "El apellido es requerido.",
  requiredEmail: "El correo electrónico es requerido.",
  requiredPhone: "El número de teléfono es requerido.",
  newsletterError: "Algo salió mal. Por favor, intente de nuevo.",
  newsletterSuccess: "Se ha suscrito satisfactoriamente.",
  dataSuccess: "Se han enviado sus datos satisfactoriamente.",
};

export default messages;
