import { Row, Col } from "antd";

import "./Wealth.css";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";

const Wealth = ({
  title,
  subtitle,
  description,
  button,
  onChangeHandler,
}: {
  title: string;
  subtitle: string;
  description: string;
  button: string;
  onChangeHandler: (cpnt: string) => void;
}) => {
  return (
    <div
      className="wealth"
      onMouseEnter={() => onChangeHandler("wealth")}
      onTouchStart={() => onChangeHandler("wealth")}
    >
      <Row justify="center" className="wealth-row">
        <Col xs={20} className="wealth-col-text">
          <h4>{title}</h4>
          <h1>{subtitle}</h1>
          <p>{description}</p>
        </Col>
        <Col xs={20} className="wealth-col-arrow">
          <p>&#8595;</p>
        </Col>
      </Row>
      <WhatsappCpnt button={button} />
    </div>
  );
};

export default Wealth;
