import { useState } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { IParallax } from "@react-spring/parallax";

import "./Footer.css";
import { labels, placeholders } from "../../fixtures/footer.fixture";
import messages from "../../fixtures/messages.fixture";
import twoforall from "../../assets/logos/twoforallWhite.svg";
import handUp from "../../assets/images/handUp.png";
import whatsapplogo from "../../assets/images/whatsapp.png";
import openWhatsapp from "../../utils/openWhatsapp";
import subscribeNewsletter from "../../utils/subscribeNewsletter";
import scrollAction from "../../utils/scrollAction";

const Footer = ({
  parallax,
  goHome = false,
}: {
  parallax?: React.RefObject<IParallax>;
  goHome?: boolean;
}) => {
  const history = useHistory();
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const onNewsletterHandler = async () => {
    if (!name) {
      message.error(messages.requiredName);
      return;
    }

    if (!lastname) {
      message.error(messages.requiredLastName);
      return;
    }

    if (!email) {
      message.error(messages.requiredEmail);
      return;
    }

    const { subscribed, error } = await subscribeNewsletter({
      name,
      lastname,
      email,
    });

    if (subscribed) {
      message.success(messages.newsletterSuccess);
      return;
    }
    if (error) {
      message.error(messages.newsletterError);
      return;
    }
  };

  return (
    <div className="Footer">
      <div className="Footer-Top">
        <div className="Footer-Title">
          <img src={twoforall} alt="Twoforall" />
        </div>
        <div className="Footer-Newsletter">
          <div className="Newsletter-Description">
            <h2>{labels.subscribe}</h2>
            <p>{labels.subscribeDescription}</p>
          </div>
          <div className="Newsletter-Form">
            <div className="Newsletter-InputName">
              <input
                placeholder={placeholders.name}
                onChange={(event) => setName(event.target.value)}
              />
              <input
                placeholder={placeholders.surname}
                onChange={(event) => setLastname(event.target.value)}
              />
            </div>
            <div className="Newsletter-Input">
              <input
                placeholder={placeholders.email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="Newsletter-Button">
              <button onClick={onNewsletterHandler}>{labels.button}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-Middle">
        <div className="Middle-Left">
          <div className="Middle-First">
            <p
              onClick={() =>
                window.open(
                  "https://www.instagram.com/twoforall.mx/?utm_medium-copy_link",
                  "_blank"
                )
              }
            >
              {labels.instagram}
            </p>
            <p
              onClick={() =>
                window.open(
                  "https://www.facebook.com/Twoforall-106978931540557",
                  "_blank"
                )
              }
            >
              {labels.map}
            </p>
            <p onClick={() => history.push("/ayuda")}>{labels.questions}</p>
            <br />
            <p onClick={() => history.push("/aviso")}>{labels.privacy}</p>
            <p onClick={() => history.push("/terminos")}>{labels.legal}</p>
          </div>
          <div className="Middle-Container">
            <h1>{labels.help}</h1>
            {labels.helpDescription}
            <p>{labels.city}</p>
            <h3>{labels.contactEmail}</h3>
          </div>
        </div>
        <div className="Middle-Right">
          <div className="Middle-ContainerTwo">
            <h1>{labels.contact}</h1>
            <p>{labels.contactDescription}</p>
            <p>{labels.city}</p>
            <h3>{labels.contactEmail}</h3>
          </div>
          <div className="Middle-ContainerThree">
            <img
              src={handUp}
              alt="hand up"
              onClick={() => scrollAction({ page: 0, parallax, goHome })}
            />
            <p>{labels.onlyUp}</p>
          </div>
        </div>
      </div>
      <div className="Newsletter-Mobile">
        <div className="NewsletterMobile-Desc">
          <h2>{labels.subscribe}</h2>
          <p>{labels.subscribeDescription}</p>
        </div>
        <div className="NewsletterMobile-Form">
          <div className="NewsletterMobile-InputName">
            <input
              placeholder={placeholders.name}
              onChange={(event) => setName(event.target.value)}
            />
            <input
              placeholder={placeholders.surname}
              onChange={(event) => setLastname(event.target.value)}
            />
          </div>
          <div className="NewsletterMobile-Input">
            <input
              placeholder={placeholders.email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="NewsletterMobile-Button">
            <button onClick={onNewsletterHandler}>{labels.button}</button>
          </div>
        </div>
      </div>
      <div className="Footer-Copyright">
        <p>
          &copy; {labels.copyright}
          <i>{labels.studioAnotherKind}</i>
        </p>
      </div>
      <div className="Footer-Bottom">
        <img src={twoforall} alt="Twoforall" className="TwoforallImage" />
        <img
          src={whatsapplogo}
          alt="whatsapp"
          onClick={openWhatsapp}
          className="WhatsappImage"
        />
      </div>
    </div>
  );
};

export default Footer;
