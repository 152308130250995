import { useRef, useState } from "react";
import { Layout } from "antd";
import { IParallax } from "@react-spring/parallax";
import HeaderCpnt from "../components/Header/Header";
import FooterCpnt from "../components/Footer/Footer";
import BlogHome from "../components/Home/BlogHome/BlogHome";
import OurServices from "../components/Home/OurServices/OurServices";
import Sticky from "../components/UI/Sticky";
import twoforallWhite from "../assets/logos/twoforallWhite.svg";
import twoforallBlack from "../assets/logos/twoforallBlack.svg";
import closeWhite from "../assets/icons/cancelWhite.png";
import closeBlack from "../assets/icons/cancel.png";
import menuWhite from "../assets/icons/recWhite.png";
import menuBlack from "../assets/icons/rec.png";
import labels from "../fixtures/home.fixture";
import "../App.css";

const { Header, Footer, Content } = Layout;

const Home = () => {
  const parallax = useRef<IParallax>(null);
  const { ourServices } = labels;
  const [color, setColor] = useState<string>("#fff");
  const [image, setImage] = useState<string>(twoforallWhite);
  const [background, setBackground] = useState<string>("#000");
  const [height, setHeight] = useState<string>("10%");
  const [closeImage, setCloseImage] = useState<string>(closeWhite);
  const [menuImage, setMenuImage] = useState<string>(menuWhite);

  const onChangeHandler = (cpnt: string) => {
    switch (cpnt) {
      case "introduction":
        setColor("#fff");
        setImage(twoforallWhite);
        setBackground("#000");
        setCloseImage(closeWhite);
        setMenuImage(menuWhite);
        break;
      case "find":
        setColor("#000");
        setImage(twoforallBlack);
        setBackground("#edb8bb");
        setCloseImage(closeBlack);
        setMenuImage(menuBlack);
        break;
      case "services":
        setColor("#fff");
        setImage(twoforallWhite);
        setBackground("#cb333b");
        setCloseImage(closeWhite);
        setMenuImage(menuWhite);
        break;
      case "blog":
        setColor("#000");
        setImage(twoforallBlack);
        setBackground("#fff");
        setCloseImage(closeBlack);
        setMenuImage(menuBlack);
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          height,
          background,
          padding: 0,
          lineHeight: 1.5715,
        }}
      >
        <HeaderCpnt
          image={image}
          closeImage={closeImage}
          menuImage={menuImage}
          color={color}
          button="Conoce tu perfil"
          setHeight={setHeight}
        />
      </Header>
      <Content>
        <Sticky
          parallax={parallax}
          labels={labels}
          onChangeHandler={onChangeHandler}
        />
        <OurServices
          title={ourServices.title}
          buttonLabel={ourServices.button}
          titleRight={ourServices.titleRight}
          subtitleRight={ourServices.subtitleRight}
          descriptionRight={ourServices.descriptionRight}
          button="Agenda tu cita"
          onChangeHandler={onChangeHandler}
        />
        <BlogHome button="Agenda tu cita" onChangeHandler={onChangeHandler} />
      </Content>
      <Footer style={{ padding: 0 }}>
        <FooterCpnt parallax={parallax} goHome={true} />
      </Footer>
    </Layout>
  );
};

export default Home;
