import { Radio } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useState } from "react";

import "./Step.css";
import labels from "../../fixtures/steps.fixture";
import Steps from "../../enums/steps.enum";
import { decreaseOutgoings } from "../../utils/setScoreProfile";

const Step6 = ({
  outgoings,
  onPreviousHandler,
  onNextHandler,
  setBeginner,
  setIntermediate,
  setAdvanced,
}: {
  outgoings: "a" | "b" | "c" | "d" | "e";
  onPreviousHandler: (previousStep: Steps) => void;
  onNextHandler: (nextStep: Steps) => void;
  setBeginner: React.Dispatch<React.SetStateAction<number>>;
  setIntermediate: React.Dispatch<React.SetStateAction<number>>;
  setAdvanced: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { title, description, option1, option2 } = labels.step6;
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const onRightHandler = () => {
    if (selectedValue) {
      onNextHandler(Steps.seven);
    }
  };

  const onLeftHandler = () => {
    decreaseOutgoings({ outgoings, setBeginner, setIntermediate, setAdvanced });
    onPreviousHandler(Steps.five);
  };

  return (
    <div className="Step">
      <div className="Step-Top">
        <div className="Step-Left">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="Step-Right">
          <Radio.Group
            className="Step-Radio"
            onChange={(e) => setSelectedValue(e?.target?.value)}
          >
            <Radio.Button value="a" className="Step-RadioButton">
              {option1}
            </Radio.Button>
            <Radio.Button value="b" className="Step-RadioButton">
              {option2}
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className="Step-Bottom">
        <ArrowLeftOutlined className="Step-Arrows" onClick={onLeftHandler} />
        <div className="Progress">
          <div className="Progress-Bar" style={{ width: "55%" }} />
        </div>
        <ArrowRightOutlined className="Step-Arrows" onClick={onRightHandler} />
      </div>
    </div>
  );
};

export default Step6;
