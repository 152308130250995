import { useRef } from "react";
import { Parallax, IParallax } from "@react-spring/parallax";
import "./OurMethod.css";
import Page from "../../UI/Page";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";
import scrollAction from "../../../utils/scrollAction";
import getDevice from "../../../utils/getters/getDevice";

interface StepLabels {
  title: string;
  description: string;
}

const OurMethod = ({
  title,
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
  button,
  onChangeHandler,
}: {
  title: string;
  stepOne: StepLabels;
  stepTwo: StepLabels;
  stepThree: StepLabels;
  stepFour: StepLabels;
  button: string;
  onChangeHandler: (cpnt: string) => void;
}) => {
  const parallax = useRef<IParallax>(null);
  const isMobile = getDevice() === "mobile";

  return (
    <div
      className="OurMethod"
      onMouseEnter={() => onChangeHandler("method")}
      onTouchStart={() => onChangeHandler("method")}
    >
      <div className="OurMethod-Title">
        <h3>{title}</h3>
      </div>
      <div className="OurMethod-Content">
        <Parallax
          ref={parallax}
          pages={2}
          horizontal
          className="methods-parallax"
        >
          <Page
            offset={0}
            onClick={() => scrollAction({ page: 1, parallax })}
            pageLabels={{
              titleLeft: "01",
              subtitleLeft: stepOne.title,
              descriptionLeft: stepOne.description,
              titleRight: "02",
              subtitleRight: stepTwo.title,
              descriptionRight: stepTwo.description,
            }}
          />
          <Page
            offset={1}
            onClick={() => scrollAction({ page: 0, parallax })}
            pageLabels={{
              titleLeft: "03",
              subtitleLeft: stepThree.title,
              descriptionLeft: stepThree.description,
              titleRight: "04",
              subtitleRight: stepFour.title,
              descriptionRight: stepFour.description,
            }}
          />
        </Parallax>
      </div>
      <WhatsappCpnt button={button} height={isMobile ? "5%" : "10%"} />
    </div>
  );
};

export default OurMethod;
