import LabelsAboutInterface from "../interfaces/about.interface";

const labels: LabelsAboutInterface = {
  aboutUs: {
    title: "Sobre Twoforall",
    subtitle: "Inversión a escala humana y al alcance de la mano",
    description:
      "La inversión, las estrategias y los portafolios de inversión no tendrían por qué ser confusos o intimidantes, sin importar tu perfil, edad, experiencia o conocimiento previo. Encontremos tu mejor opción y tu siguiente gran oportunidad.",
  },
  investment: {
    title: "Investment is for people, and people are all different",
    description:
      "Las posibilidades, formas y opciones de inversión son tan únicas y diversas como las personas, sus planes e intereses. Una gran estrategia también debería serlo.",
  },
  ourMethod: {
    title: "Nuestro método",
    titleStepOne: "Escuchamos",
    descriptionStepOne:
      "¿Quieres tener mayor liquidez y tener tu dinero disponible? ¿O tal vez invertir a largo plazo o construir un patrimonio? Primero queremos entender tus intereses, objetivos y necesidades únicas.",
    titleStepTwo: "Analizamos",
    descriptionStepTwo:
      "Examinamos el mercado y detectamos patrones y oportunidades para que tu dinero siempre esté invertido de acuerdo a tus plazos deseados, tolerancia al riesgo y activos disponibles.",
    titleStepThree: "Acompañamos",
    descriptionStepThree:
      "Todo equipo requiere de gran colaboración y comunicación clara. Implementamos, guiamos y optimizamos tu estrategia contigo para siempre mantenerte al frente de las tendencias del mercado.",
    titleStepFour: "Garantizamos",
    descriptionStepFour:
      "Grandes estrategias para grandes planes. Pondremos toda nuestra experiencia a trabajar y haremos todo lo posible por asegurar que tu dinero genere los flujos y rendimientos que buscas, y con garantías únicas.",
  },
  twoforall: {
    title: "Sobre Twoforall",
    title2: "Consultores Especializados.",
    description2:
      "Nuestro equipo incluye a consultores líder y una red extraordinaria de asesores financieros y especialistas en distintas industrias que nos permiten –esto te incluye– generar planes y estrategias de inversión en donde la comunicación, la liquidez, el dinamismo y la diversificación son la clave.",
    title3: "Tú eres Twoforall.",
    description3:
      "Nuestro equipo de asesores especializados se encargará de mantenerte a la vanguardia e informado de las tendencias, cambios y oportunidades del mercado, de acuerdo a tus necesidades, planes y portafolio únicos. Recuerda: no eres un cliente o un número, sino un miembro central de este equipo.",
    link: "Aprende más sobre nuestros servicios >",
  },
};

export default labels;
