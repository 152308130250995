enum Steps {
  one = "firstStep",
  two = "secondStep",
  three = "thirdStep",
  four = "fourthStep",
  five = "fifthStep",
  six = "sixthStep",
  seven = "seventhStep",
  eight = "eighthStep",
  nine = "ninthStep",
  final = "finalStep",
}

export default Steps;
