import "./Introduction.css";
import peak from "../../../assets/images/peak.png";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";

const Introduction = ({ title, button }: { title: string; button: string }) => (
  <div className="Introduction">
    <div className="Introduction-Top">
      <div className="Introduction-Left">
        <h1>{title}</h1>
      </div>
      <div className="Introduction-Right">
        <img src={peak} alt="Peak" />
        <p>&#8595;</p>
      </div>
    </div>
    <WhatsappCpnt button={button} />
  </div>
);

export default Introduction;
