import labels from "../../fixtures/terms.fixture";
import "./TermsCpnt.css";

const TermsCpnt = () => {
  return (
    <div className="terms">
      <h1>{labels.title}</h1>
      <p>{labels.introduction}</p>
      <p>{labels.introductionInfo}</p>
      <h2>{labels.subtitleObj}</h2>
      <p>{labels.ObjIntro}</p>
      <p>{labels.modifyWebsite}</p>
      <p>{labels.priceServices}</p>
      <p>{labels.registerInfo}</p>
      <p>{labels.websiteLegalAge}</p>
      <p>{labels.websiteMexican}</p>
      <p>{labels.userCanManage}</p>
      <h2>{labels.subtitleCond}</h2>
      <p>{labels.firstCond}</p>
      <p>{labels.condRecomend}</p>
      <p>{labels.secondCond}</p>
      <p>{labels.thirdCond}</p>
      <p>{labels.fourthCond}</p>
      <p>{labels.userShouldNotify}</p>
      <p>{labels.fifthCond}</p>
      <p>{labels.acceptTerms}</p>
      <h2>{labels.subtitlePriv}</h2>
      <p>{labels.firstPriv}</p>
      <p>{labels.dataPersonal}</p>
      <p>{labels.secondPriv}</p>
      <p>{labels.thirdPriv}</p>
      <p>{labels.fourthPriv}</p>
      <p>{labels.fifthPriv}</p>
      <p>{labels.url}</p>
    </div>
  );
};

export default TermsCpnt;
