import { Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import "./FindYourProfile.css";
import handInvert from "../../../assets/images/handInvert.png";
import peak from "../../../assets/images/peak.png";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";

const FindYourProfile = ({
  title,
  description,
  findProfile,
  buttonLabel,
  quote,
  link,
  button,
}: {
  title: string;
  description: JSX.Element;
  findProfile: string;
  buttonLabel: string;
  quote: string;
  link: string;
  button: string;
}) => {
  const history = useHistory();

  return (
    <div className="find-out">
      <Row justify="center" className="find-out-row">
        <Col xs={20} lg={12} className="find-out-col-text">
          <h1>{title}</h1>
          {description}
          <h3>{findProfile}</h3>
          <a href="/profile">{link}</a>
          <div className="find-out-start">
            <img src={handInvert} alt="Hand" />
            <button onClick={() => history.push("/profile")}>
              {buttonLabel}
            </button>
          </div>
        </Col>
        <Col xs={20} lg={6} className="find-out-col-img">
          <img src={peak} alt="Peak" />
          <p>{quote}</p>
        </Col>
      </Row>
      <WhatsappCpnt button={button} />
    </div>
  );
};

export default FindYourProfile;
