import LabelsTermsInterface from "../interfaces/terms.interface";

const labels: LabelsTermsInterface = {
  title: "Términos & Condiciones",
  introduction:
    "Este contrato describe los términos y condiciones generales del uso de los contenidos, productos y servicios ofrecidos a través del sitio www.twoforall.mx, del cual es titular Twoforall, S.A. de C.V.",
  introductionInfo:
    "Toda persona que acceda o haga uso del sitio web y de los servicios que en él se ofrecen, deberá hacerlo bajo los presentes términos y condiciones, así como políticas y principios establecidos en éstos. En caso de no aceptar estos términos y condiciones, deberá abstenerse de utilizar el sitio web y/o adquirir los servicios que en éste son ofrecidos.",
  subtitleObj: "Objeto",
  ObjIntro:
    "El objeto de estos términos y condiciones es regular el acceso y uso del sitio web www.twoforall.mx, y por tanto, de su contenido; es decir, la información y los productos y servicios que en éste se ofrecen y se encuentran a disposición del público en general.",
  modifyWebsite:
    "El titular de este sitio web se reserva el derecho de modificar en cualquier momento y sin previo aviso el contenido, presentación, diseño, funcionalidad, la configuración y, en especial, los productos y/o servicios que ofrece al público en general. Por tanto, el usuario acepta que el titular podrá en cualquier momento interrumpir, desactivar o cancelar cualquiera de los elementos que componen el sitio web o el acceso a los mismos.",
  priceServices:
    "En caso de que, además del costo de la conexión que el usuario tenga contratado con su proveedor de servicio de telecomunicaciones, éste tenga que contratar el acceso a algún contenido, producto y/o servicios a través de este sitio web o de terceros, el titular pondrá a su disposición las condiciones generales o particulares por las que se regule el acceso a dichos contenidos.",
  registerInfo:
    "Asimismo, el usuario reconoce y acepta que el acceso a parte de los contenidos y servicios del sitio web podrá́ realizarse previa suscripción o registro previo.",
  websiteLegalAge:
    "El sitio web www.twoforall.mx se encuentra dirigido exclusivamente a personas mayores de 18 (dieciocho) años; en este sentido, el usuario deslinda de toda responsabilidad a Twoforall, S.A. de C.V. de cualquier responsabilidad por el incumplimiento de este requisito.",
  websiteMexican:
    "El sitio web www.twoforall.mx está dirigido principalmente a usuarios residentes en la Republica Mexicana, por lo cual, Twoforall S.A. de C.V., no asegura que el sitio web cumpla total o parcialmente con la legislación de otros países. Por tanto, si el usuario reside o tiene su domicilio establecido en otro país y decide acceder o utilizar el sitio web lo hará bajo su propia responsabilidad y deberá asegurarse de que tal acceso y navegación cumple con la legislación vigente en su lugar de residencia. En ese sentido, Twoforall S.A. de C.V. no asume responsabilidad alguna que se pueda derivar de dicho uso.",
  userCanManage:
    "El usuario en este momento se hace conocedor de que Twoforall S.A. de C.V., podrá administrar o gestionar su sitio web de manera directa o por conducto de terceros, lo que en forma alguna modifica lo regulado en estos términos y condiciones.",
  subtitleCond: "Condiciones",
  firstCond:
    "I. Con el acceso o uso del sitio web, la persona adquiere la calidad de usuario, por lo que desde ese momento, se tienen por aceptados y queda sujeto a lo establecido en éstos términos y condiciones, así como a posteriores modificaciones, sin perjuicio de lo que establezcan las leyes aplicables.",
  condRecomend:
    "En virtud de lo antes señalado, se recomienda al usuario estar en todo momento actualizado respecto de los presentes términos y condiciones, así como sus modificaciones en el futuro.",
  secondCond:
    "II. El usuario es responsable del uso del sitio web tal cual fue diseñado y puesto a disposición del público. Por tanto, tiene absolutamente prohibido la utilización de cualquier tipo de software que modifique, mediante procesos automatizados, la interacción o descarga de los contenidos, productos y/o servicios proporcionados a través del sitio web.",
  thirdCond:
    "III. El usuario es responsable del uso lícito y de acuerdo a los presentes términos y condiciones de los contenidos, productos y/o servicios ofrecidos a través del sitio web. Asimismo, se abstendrá de afectar derechos de terceros o perjudicar el funcionamiento del sitio web.",
  fourthCond:
    "IV. El usuario se obliga a proporcionar información verdadera y lícita en los formularios proporcionados en el sitio web, por los cuales obtenga información de algún contenido, producto y/o servicios ofrecido por en el sitio web.",
  userShouldNotify:
    "El usuario deberá notificar de forma inmediata al titular del sitio web acerca de cualquier hecho que permita suponer el uso indebido de la información registrada en dichos formularios, tales como, robo, extravió, o acceso no autorizado a cuentas y/o contraseñas, con el fin de que el titular proceda a tomar las medidas pertinentes.",
  fifthCond:
    "V. El sólo acceso al sitio web no implica la creación de ningún tipo de relación entre el titular y el usuario.",
  acceptTerms:
    "En este momento, el usuario, al ser mayor de edad, manifiesta que tiene la capacidad jurídica para sujetarse a los presentes términos y condiciones.",
  subtitlePriv: "Política de Privacidad y Protección de Datos",
  firstPriv:
    "I. Para dar cumplimiento a las obligaciones establecidas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, el titular se compromete a llevar a cabo las medidas necesarias para la protección de los datos personales que recabe a través de su sitio web, con el fin de garantizar su seguridad y evitar su alteración, pérdida o tratamiento indebido.",
  dataPersonal:
    "Asimismo, el titular se compromete a tratar los datos personales que recabe conforme a los principios de licitud, calidad, finalidad, lealtad y responsabilidad establecidos en dicha norma.",
  secondPriv:
    "II. La utilización de datos financieros o patrimoniales, requerirán de autorización expresa de sus titulares, la cual podrá darse a través del propio sitio web, entendiéndose que si los proporciona, lo hace con conocimiento y consentimiento libre. Por su parte, el titular dará el mayor cuidado a este tipo de datos.",
  thirdPriv:
    "III. El mismo tratamiento recibirá cualquier dato considerado sensible por la ley antes señalada, en tanto que su uso y tratamiento indebido pueden tener como efecto que se discrimine o se ponga en riesgo al titular de dichos datos personales. por lo que Twoforall S.A. de C.V. cuidará con la diligencia debida dichos datos.",
  fourthPriv:
    "IV. En todo momento se procurará que los datos personales contenidos en las bases de datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los fines para los cuales fueron recabados.",
  fifthPriv:
    "V. El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en el Aviso de Privacidad el cual se encontrará disponible en la siguiente dirección electrónica:",
  url: "www.twoforall.mx/aviso",
};

export default labels;
