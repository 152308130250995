import "./Invesment.css";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";
import squeeze from "../../../assets/gifs/squeeze.gif";

const Invesment = ({
  title,
  description,
  button,
  transform,
  onChangeHandler,
}: {
  title: string;
  description: string;
  button: string;
  transform: string;
  onChangeHandler: (cpnt: string) => void;
}) => (
  <div
    className="investment"
    id="invest"
    onMouseEnter={() => onChangeHandler("investment")}
    onTouchStart={() => onChangeHandler("investment")}
  >
    <div className="investment-content">
      <h2>{title}</h2>
      <h1>{description}</h1>
    </div>
    <img
      style={{ transform }}
      className="investment-img"
      id="img-invest"
      src={squeeze}
      alt="HandsGif"
    />
    <WhatsappCpnt button={button} />
  </div>
);

export default Invesment;
