import { useHistory } from "react-router-dom";
import "./BlogHome.css";
import blogHomeMock from "../../../mocks/blogHome.mock";
import buildings from "../../../assets/mocks/blogImageMock.png";
import mockImage from "../../../assets/mocks/blogImageMock2.png";
import WhatsappCpnt from "../../UI/WhatsappCpnt/WhatsappCpnt";

const BlogHome = ({
  button,
  onChangeHandler,
}: {
  button: string;
  onChangeHandler: (cpnt: string) => void;
}) => {
  const history = useHistory();
  const {
    title,
    subtitle,
    invest,
    market,
    quote,
    authorQuote,
    titleFirstArticle,
    authorFirstArticle,
    titleSecondArticle,
    authorSecondArticle,
    titleThirdArticle,
    authorThirdArticle,
    descriptionThirdArticle,
  } = blogHomeMock;

  return (
    <div
      className="BlogHome"
      onMouseEnter={() => onChangeHandler("blog")}
      onTouchStart={() => onChangeHandler("blog")}
    >
      <div className="BlogHome-Title">
        <div className="BlogHome-Left-Title">
          <div className="BlogHome-Title-Content">
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
          </div>
        </div>
        <div className="BlogHome-Right-Title">
          <h1>{quote}</h1>
          <h3>{authorQuote}</h3>
          <div className="BlogHome-Right-TitleMobile">
            <h4>{authorQuote}</h4>
          </div>
          <h4>{subtitle}</h4>
        </div>
      </div>
      <div className="BlogHome-Top">
        <div className="BlogHome-Left">
          <div className="BlogHome-Left-Content">
            <img
              src={buildings}
              alt="imageArticle"
              onClick={() => history.push("/article/0")}
            />
            <h4>{invest}</h4>
            <h2 onClick={() => history.push("/article/0")}>
              {titleFirstArticle}
            </h2>
            <h4>{authorFirstArticle}</h4>
            <hr />
            <h4>{market}</h4>
            <h2 onClick={() => history.push("/article/0")}>
              {titleSecondArticle}
            </h2>
            <h4>{authorSecondArticle}</h4>
          </div>
        </div>
        <div className="BlogHome-Right">
          <div className="BlogHome-Right-Content">
            <img
              src={mockImage}
              alt="imageMock"
              onClick={() => history.push("/article/0")}
            />
            <h4>{invest}</h4>
            <h2 onClick={() => history.push("/article/0")}>
              {titleThirdArticle}
            </h2>
            <p onClick={() => history.push("/article/0")}>
              {descriptionThirdArticle}
            </p>
            <h4>{authorThirdArticle}</h4>
          </div>
        </div>
      </div>
      <WhatsappCpnt button={button} />
    </div>
  );
};

export default BlogHome;
