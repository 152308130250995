import { ParallaxLayer } from "@react-spring/parallax";
import "../About/OurMethod/OurMethod.css";

interface PageProps {
  offset: number;
  pageLabels: {
    titleLeft: string;
    subtitleLeft: string;
    descriptionLeft: string;
    titleRight: string;
    subtitleRight: string;
    descriptionRight: string;
  };
  onClick: () => void;
}

const Page = ({ offset, pageLabels, onClick }: PageProps) => (
  <ParallaxLayer offset={offset} speed={0.2} onClick={onClick}>
    <div className="OurMethod-Parallax">
      <div className="OurMethod-Parallax-Arrow">
        <p>&#8592;</p>
      </div>
      <div className="OurMethod-Parallax-Left">
        <h1>{pageLabels.titleLeft}</h1>
        <h2>{pageLabels.subtitleLeft}</h2>
        <p className="OurMethod-Paragraph">{pageLabels.descriptionLeft}</p>
      </div>
      <div className="OurMethod-Parallax-Middle">
        <p>&#8592;</p>
        <p>&#8594;</p>
      </div>
      <div className="OurMethod-Parallax-Right">
        <h1>{pageLabels.titleRight}</h1>
        <h2>{pageLabels.subtitleRight}</h2>
        <p className="OurMethod-Paragraph">{pageLabels.descriptionRight}</p>
      </div>
      <div className="OurMethod-Parallax-Arrow">
        <p>&#8594;</p>
      </div>
    </div>
  </ParallaxLayer>
);

export default Page;
